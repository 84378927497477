.react-slideshow-wrapper.slide {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-container .default-nav:last-of-type,
.react-slideshow-container .default-nav:first-of-type {
  display: none;
}

.each-slide {
  display: flex;
}

.title-descricao-container-banner-padrao {
  h1 {
    @include display-2-serif;
    padding: 10rem 1.5rem 0rem 7.5rem;
  }
}

.paragrafo-descricao-container-banner-padrao {
  padding: 2rem 1.5rem 0rem 7.5rem;
  p {
    @include body-xlarge-sans-serif;
  }
}

.expositor-descricao-container-banner-padrao {
  background-color: $bg-dark-5;
  flex: 1;
}

.dYWSjd {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: block !important;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  img {
    width: 100%;
  }
}

.iDfeOS {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0px !important;
}

.glAxCh {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  font-size: 1.3em;
  content: "";
  border-radius: 50%;
  outline: none;
  height: 17px !important;
  width: 17px !important;
}

.banner-padrao-container {
  cursor: pointer;
  .fyLrfx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: absolute;
  }
}

.fSoGyI {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin-left: 13px !important;
  margin-right: 13px !important;
  background-color: white !important;
  box-sizing: 0px !important;
  font-size: 1.3em;
  content: "";
  height: 15px !important;
  width: 15px !important;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 1px 2px $bg-light !important;
}

.container-mobile-img {
  width: 100%;
}
