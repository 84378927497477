/* ProgressBar.css */
.custom-progress-bar {
  width: 100%;
}

.container-progress-bar {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  background: rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

.container-progress-bar h3 {
  color: var(--light-primary-dark, #e4e4eb) !important;
  margin: unset !important;

  /* Body - sans serif - regular & bold/Body Small - Sans serif - Bold */
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.certificado-curso {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e4e4eb;
  img {
    width: 30px;
    filter: invert(96%) sepia(4%) saturate(227%) hue-rotate(201deg)
      brightness(92%) contrast(101%);
  }

  &.click {
    cursor: pointer;
    &:hover {
      color: #c9c9d1 ;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container-progress-bar {
    display: flex;
    padding: 15.809px;
    flex-direction: column;
    align-items: flex-start;
    gap: 23.713px;
    align-self: stretch;
    margin: 0;
  }

  .container-progress-bar h3 {
    font-size: 14px;
    line-height: 20px;
  }
}
