.header-container {
  padding: 40px 0;
  background-color: $bg-color;
  
  h1 {
    text-align: center;
    @include display-1-serif;
  }

  hr {
    background-color: $bg-color;
    border: 0;
    height: 2px;
  }
}

.input-container {
  padding-top: 3.187rem;
  justify-content: center;

  .input1 {
    input {
      width: 100%;
      height: 48px;
      padding-left: 15px;
      @include body-normal-sans-serif;
    }

    .form-control {
      width: 284px;
      border: 1px solid black;
    }
  }

  .input2 {
    border: 1px solid black;

    .dropdown-container {
      padding: 4px 0 4px 0;
      border: none;
    }
    .gray {
      @include body-normal-sans-serif;
    }
  }

  .input3 {
    border: 1px solid black;

    .dropdown-container {
      padding: 4px 0 4px 0;
      border: none;
    }

    .gray {
      @include body-normal-sans-serif;
    }
  }
}

button {
  @include body-normal-sans-serif;
  font-size: 15px;
  border: none;
  background-color: white;
}

.navegue-pela-letra-container {
  padding-top: 60px;
  .navegue-h2 {
    text-align: center;
    padding-bottom: 38px;
    h2 {
      @include body-xlarge-serif;
    }
  }

  .pesquisa-por-letra-container {
    text-align: center;
  }
}
.alfabeto-lista-container {
  li {
    list-style: none;
  }

  a {
    @include body-medium-sans-serif;
    padding-left: 36px;
    color: $bg-dark-4;
    &:hover {
      color: $bg-dark-4;
    }
  }
}

.expositores-container {
  padding-top: 4.812rem;

  .lista-expositores {
    display: flex;
  }

  .sentinela-container {
    width: 100%;
    height: 180px;
  }

  .expositores-card-letra-container {
    .div-container {
      display: flex;
      flex-wrap: wrap;
    }

    h6 {
      text-transform: uppercase;
      text-align: center;
    }

    .letra-filtrada {
      width: 70px;
      h1 {
        @include display-2-serif;
      }
    }
    .expositores-card {
      border: 2px solid #9C9C9C;
      width: 100%;
      height: 100%;
      margin-bottom: 40px;
      padding: 5px;
      display: flex;
      background-color: $bg-light;
      justify-content: center;
      cursor: pointer;
      &:hover {
        filter: drop-shadow(0px 2px 8px rgba(40, 41, 61, 0.08))
          drop-shadow(0px 20px 32px rgba(96, 97, 112, 0.24));
      }

      .image-logo {
        display: flex;
        align-items: center;

        img {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  .voltar-ao-topo {
    display: flex;
    padding-top: 10px;

    #seta-para-cima {
      transform: rotate(90deg);
    }

    .hr1 {
      height: 1px;
      background-color: #f87fb4;
      flex: 35;
      margin-top: 14px;
    }

    .hr2 {
      height: 1px;
      background-color: #f87fb4;
      flex: 1;
      margin-top: 14px;
    }

    button {
      border: none;
      background-color: transparent;
      @include label-normal-sans-serif;
      align-items: baseline;
      font-size: 14px;
    }
  }
}

.container-sem-produtos {
  margin: 4% 4%;
}

.mostrando-container {
  text-align: center;
  padding: 101px 0px;
  button {
    margin-top: 62px;
  }

  h2 {
    @include body-large-serif;
    font-size: 30px;
  }
}

.rmsc .dropdown-container:focus-within {
  box-shadow: black 0 0 0 0px !important;
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
  // titulo
  .header-container {
    h1 {
      @include display-2-serif;
    }
  }

  .navegue-pela-letra-container .navegue-h2 h2 {
    font-size: 2em;
    line-height: 44px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  // titulo
  .header-container {
    h1 {
      @include headline-2-serif;
    }
  }
  .navegue-pela-letra-container {
    .navegue-h2 {
      h2 {
        font-size: 1.7em;
        line-height: 44px;
      }
    }

    .pesquisa-por-letra-container {
      font-size: 0.9em;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  // titulo
  .header-container {
    h1 {
      @include headline-2-serif;
    }
  }

  .expositores-container .expositores-card-letra-container .letra-filtrada h1 {
    font-size: 2.6em;
  }

  .navegue-pela-letra-container {
    .navegue-h2 {
      h2 {
        font-size: 1.7em;
        line-height: 44px;
      }
    }

    .pesquisa-por-letra-container {
      font-size: 0.9em;
    }
  }
}

@media (max-width: 767px) {
  .header-container {
    h1 {
      @include headline-3-serif;
    }
  }

  .input-container {
    padding-top: 0rem;
  }

  .input-container {
    .input1,
    .input2,
    .input3 {
      margin-bottom: 0.7rem;
    }
  }

  .expositores-container {
    padding-top: 0px;
  }

  .navegue-pela-letra-container {
    .navegue-h2 {
      h2 {
        font-size: 1.6em;
        line-height: 44px;
      }
    }

    .pesquisa-por-letra-container {
      font-size: 0.9em;
    }
  }

  .alfabeto-lista-container a {
    @include body-normal-sans-serif;
    padding: 0px 10px;
    color: #8c8c8c;
  }
}
