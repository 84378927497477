.evento{
border: 1px solid $bg-dark-5;
  &:hover {
    -webkit-box-shadow: 0px 8px 16px 0px rgba(96, 97, 112, 0.16),
      0px 2px 4px 0px rgba(96, 97, 112, 0.04);
    box-shadow: 0px 8px 16px 0px rgba(96, 97, 112, 0.16),
      0px 2px 4px 0px rgba(96, 97, 112, 0.04);
    cursor: pointer;
  }
}



.filtragem-e-info-container {
  padding-top: 3rem;

  .css-2b097c-container {
    width: 100%;
    cursor: pointer !important;
  }

  .limpar-filtros {
    cursor: pointer;
  }
}

.eventos-expositor-header {
  h1 {
    text-align: center;
    padding-bottom: 1.875rem;
    @include display-1-serif;
  }
  
}

.container-sem-eventos {
  padding-bottom: 120px;
}

.eventos-container {

  .row {
    gap: 16px;
  }
  .imagem-card {
    width: 100%;
    cursor: pointer;
    border: 1px solid $bg-dark-5;
  }

  .data-evento-carrosel {
    background-color: $bg-dark-1;
    position: absolute;
    width: 3.75rem;
    height: 3.75rem;
    padding-top: 0.5rem;
    margin-top: 3px;

    span {
      @include body-medium-serif;
      text-align: center;
      line-height: 120%;
      color: $bg-light;
      display: block;
    }
  }

  .evento-expositor {
    border-top: 3px solid $main-color-2;
  }

  .card-body-expositor {
    background-color: $bg-light;
    min-height: 11rem;
    padding: 0 10px 20px 10px;
    margin-bottom: 10px;

    .plataforma-evento-expositor {
      p {
        @include label-normal-sans-serif;
        font-size: 12px;
        color: black;
      }
    }

    .text-container {
      h3 {
        @include body-large-serif;
        color: black;
      }

      p {
        @include label-normal-sans-serif-1;
        color: black;
        height: 150px;
      }
    }
  }

  .card-body {
    background-color: $bg-light;
    .card-footer-container {
      display: flex;
      justify-content: space-between;
      .evento-data {
        display: flex;
        flex-direction: column;

        .evento-horas-data {
          p {
            @include body-normal-serif;
            color: $bg-dark-1;
            display: flex;
            &::before {
              padding-right: 25px;
              padding-bottom: 5px;
              content: "";
              background: url("../../../assets/img/expo-revestir/icons/relogio.svg");
              width: 20px;
              height: 25px;
              background-repeat: no-repeat;
              filter: invert(100%);
            }
          }
        }
        .evento-calendario-data {
          display: flex;
          align-items: center;
         
            &::before {
              padding-right: 25px;
              padding-bottom: 5px;
              content: "";
              background-image: url("../../../assets/img/icons/calendario.svg");
              width: 20px;
              height: 25px;
              background-repeat: no-repeat;
              
           
          }
        }
      }
    }
  }

  .card-body:hover {
      background-color: $bg-color;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      .botao-card-evento{
        border: 3px solid $bg-light !important;
        background-color: $bg-light !important;
        color: $bg-dark-1 !important;
       }
      
     }
}

@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .header-container {
    h1 {
      @include display-2-serif;
    }
  }
  .container {
    .eventos-passados-h1 {
      h1 {
        @include display-2-serif;
      }
    }
  }

  .botao-card-evento {
    padding: 10px 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-container {
    h1 {
      @include headline-2-serif;
    }
  }

  .card-body .plataforma-evento p {
    font-size: 0.8em !important;
  }

  .container {
    .eventos-passados-h1 {
      h1 {
        @include headline-2-serif;
      }
    }
  }

  .botao-card-evento {
    padding: 10px 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header-container {
    h1 {
      @include headline-2-serif;
    }
  }

  .container {
    .eventos-passados-h1 {
      h1 {
        @include headline-2-serif;
      }
    }
  }
}

@media (max-width: 767px) {
  .header-container {
    h1 {
      @include headline-3-serif;
    }
  }

  .container {
    .eventos-passados-h1 {
      h1 {
        @include headline-3-serif;
      }
    }
  }

  .filtragem-e-info-container {
    flex-direction: column-reverse;
    padding-bottom: 20px;
    gap: 40px;
    .seletor {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .css-2b097c-container {
        width: 100%;
        cursor: pointer;
      }
    }
    .limpar-filtros-container {
      padding-top: 1rem;
      text-align: center;

      span {
        font-size: 1.2em;
      }
    }
  }
}
