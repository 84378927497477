.video-card {
  display: flex;
  width: 1290px;
  height: 150px;
  padding: 50px 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background: var(--text-background-light-40, #fafafc);
  cursor: pointer;
}

.video-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.video-name {
  color: var(--text-background-dark-100, #000);
  font-family: "Roboto Slab", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}

.video-title {
  color: var(--text-background-dark-80, #202020);
  font-family: "Roboto Slab", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 133.333% */
}

.video-duration {
  color: var(--text-background-dark-60, #505050);
  font-family: "Roboto Slab", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 133.333% */
}

.progress-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

@media only screen and (max-width: 1112px) {
  .video-card {
    width: 90%;
    padding: 20px;
    gap: 10px;
    margin-left: 3%;
  }
}

@media only screen and (max-width: 768px) {
  .video-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .video-name,
  .video-title,
  .video-duration {
    font-size: 18px;
    line-height: 26px;
  }
}
