@media screen and (min-width: 1800px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1680px;
    flex-wrap: nowrap !important;
  }

  #nav-desk {
    display: flex;
  }
  .navbar-desktop {
    display: contents;
  }

  .navbar-mobile {
    display: none;
  }

  .bg-visitante {
    .container-info-visitante {
      margin: 220px 0 0 250px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1400px;
    flex-wrap: nowrap !important;
  }

  #nav-desk {
    display: flex;
  }

  .navbar-desktop {
    display: contents;
  }

  .navbar-mobile {
    display: none;
  }

  .bg-visitante {
    .container-info-visitante {
      margin: 90px 0 0 250px;

      p {
        font-size: 2.2rem;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1000px;
    flex-wrap: nowrap !important;
  }
  .duvidas-direito-header {
    img {
      padding: 0;
      width: 100%;
    }
  }

  #nav-desk {
    display: flex;
  }

  .navbar-desktop {
    display: contents;
  }

  .navbar-mobile {
    display: none;
  }

  // Footer
  .row-footer {
    width: 100%;
  }

  // Login
  .login_wrapper {
    .login_text_form {
      padding: 10% 5%;
      width: 80%;
    }

    .bg-visitante {
      background: (
        url("../../img/expo-revestir/bg-login.png")
      );

      .container-info-visitante {
        margin: 100px 0 0 100px;

        p {
          font-size: 2rem;
        }
      }
    }

    .row-right {
      margin-left: 40px;
      img {
        width: 90%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 720px;
    flex-wrap: nowrap !important;
  }

  #nav-desk {
    display: none;
  }

  .duvidas-direito-header {
    display: none;
  }

  .navbar-desktop {
    display: none;
  }
  .navbar-mobile {
    display: contents;
  }

  .navbar .nav-itens-container {
    background-color: black;
    margin: 40px 0 0 0;
  }

  .navbar .container,
  .navbar .container-fluid,
  .navbar .container-sm,
  .navbar .container-md,
  .navbar .container-lg,
  .navbar .container-xl {
    justify-content: flex-start;
  }

  .duvidas-direito-header {
    display: none;
  }
  .navbar .nav-itens-container img {
    margin-bottom: 0px;
  }

  .navbar h1 {
    margin: 0 0 0 10px;
    font-size: 2em;
  }

  .navbar-nav .nav-link {
    display: flex;
    align-items: center;
    a {
      margin-left: 5px;
      font-size: 0.75em;
    }
  }

  // Footer

  .social-media-footer {
    align-items: flex-start;
    margin-top: 20px;
  }

  // Login
  .login_wrapper {
    .bg-visitante {
      background: (
        url("../../img/expo-revestir/bg-login.png")
      );
      background-size: cover;

      .container-info-visitante {
        margin: 200px 0 0 32px;

        p {
          font-size: 1.5rem;
        }
      }
    }
    .container-login {
      margin: 10px;
    }
    .row-right {
      margin-left: 50px;
      img {
        width: 120%;
      }
    }
    .login_text {
      h1 {
        @include body-xlarge-serif;
        margin: 0 0 10px 0;
      }
      p {
        @include body-normal-sans-serif;
        margin: 0 auto;
        max-width: 80%;
        text-align: center;
      }
    }

    .form-control {
      @include label-normal-sans-serif;
    }
  }

  .login_text_form {
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100%;
    flex-wrap: nowrap !important;
  }

  #nav-desk {
    display: none;
  }

  .duvidas-direito-header {
    display: none;
  }

  .navbar-desktop {
    display: none;
  }
  .navbar-mobile {
    display: contents;
  }

  .navbar .nav-itens-container {
    background-color: black;
    margin: 20px 0 0 0;
  }

  .navbar {
    height: 100%;
    padding: 0 0 20px 0;

    .navbar-brand {
      padding: 10px 0px;
    }
  }

  .navbar .container,
  .navbar .container-fluid,
  .navbar .container-sm,
  .navbar .container-md,
  .navbar .container-lg,
  .navbar .container-xl {
    justify-content: center;
  }

  .navbar .nav-itens-container img {
    margin-bottom: 0px;
  }

  .navbar h1 {
    margin: 0 0 0 10px;
    font-size: 2em;
    line-height: 140%;
  }

  .duvidas-direito-header {
    display: none;
  }

  .navbar-nav .nav-link {
    display: flex;
    align-items: center;
    a {
      margin-left: 5px;
      font-size: 0.75em;
    }
  }

  .navbar-nav {
    padding-left: 0px;
  }

  .banner-padrao-container .fyLrfx {
    margin-top: 500px !important;
    // Footer
    .social-media-footer {
      align-items: flex-start;
      margin-top: 20px;
    }
  }

  // Login
  .login_wrapper {
    background-size: cover;
    flex-direction: column;
    justify-content: flex-start;

    .row {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    .bg-visitante {
      background: (
        url("../../img/expo-revestir/bg-login.png")
      );
      background-size: cover;
      padding: 0;

      .container-info-visitante {
        display: none;
      }

      .row-login {
        flex-direction: column-reverse;
      }
    }

    .row-right {
      img {
        display: none;
        position: absolute;
        width: 110%;
        top: -13px;
      }
    }
  }
  .login_logo {
    height: auto;
    img {
      width: 70%;
      padding: 20px 0;
      margin: 0 auto;
    }
  }

  .login_text_form {
    margin-top: -600px;
    margin-bottom: 0;
  }

  .login_text_form {
    width: 92%;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .login_text_form {
    margin-top: -580px;
    margin-bottom: 20px;
    padding: 46px 84px 100px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .login_wrapper .row-right {
    margin-left: 0;
  }
}

@media (max-width: 767px) and (min-height: 800px) {
  .login_wrapper {
    .bg-visitante {
      display: block;
      width: 100%;

      .container-info-visitante {
        display: block;
        padding: 20px;

        p {
          font-size: 2rem;
        }
      }
    }
  }
}
