.eventos-conteudos-disponiveis-container {
  background-color: $bg-light-2;
  padding-bottom: 80px;
  
}



.card-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
}
