.main-page-container {
  background-color: $main-color-2;
  padding-top: 4rem;

  .title-container {
    h1 {
      font-size: 7.5em !important;
      @include display-1-serif;
      color: $bg-light;
      line-height: 120px;
    }
  }

  .paragrafo-container {
    width: 100%;
    color: $bg-dark-1;
    padding: 1.3rem 0 3rem 0;

    h3 {
      font-size: 1.65rem;
    }
  }

  .cards-container {
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;

    .bg-card {
      width: 30.33%;
      background-color: $bg-color;

      img {
        width: 100%;
      }

      h3 {
        margin-bottom: 1.25rem;
        margin-top: 1.3rem;
        color: $main-color-3;
      }

      p {
        @include body-large-sans-serif;
        padding: 1.2rem 2rem 1.2rem 2rem;
      }
    }
  }
}

.lancamento-edicao-container {
  padding: 3.75rem 0px;
  background: $bg-color;

  .lancamento-itens {
    width: 55%;
    margin: 0 auto;
    text-align: center;

    .texto {
      color: $bg-dark-1;
      @include display-2-serif;
    }

    .botao-container {
      margin-top: 2rem;

      button {
        font-size: 1.3em;
        padding: 0.8rem 2.5rem;
      }
    }
  }
}

.sessao-eventos-do-dia {
  background-color: #ff876c;
}

.eventos-do-dia-title-container {
  padding-top: 3.3rem;
  h1 {
    text-align: center;
    @include headline-2-serif;
    color: $bg-dark-1;
  }
}

.eventos-do-dia-title-footer-container {
  margin-bottom: 3.3rem;

  h1 {
    text-align: center;
    @include body-xlarge-sans-serif;

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-image: url(../../img/expo-revestir/icons/Arrow2.svg);
      background-repeat: no-repeat;
      background-position: center;
      padding: 0px 1.25rem 0.312rem 3.75rem;
    }
  }
}

.calendario-eventos-do-dia-container {
  text-align: center;
  padding: 4rem;

  h2 {
    cursor: pointer;
    @include headline-4-sans-serif;
    color: black;

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-image: url(../../img/expo-revestir/icons/Arrow2.svg);
      background-repeat: no-repeat;
      background-position: center;
      padding: 0px 1.25rem 0.312rem 3.75rem;
    }
  }
}

.card-eventos-container {
  justify-content: space-between;

  .borda-evento {
    border-top: 3px solid $main-color-3;
    margin: 0;
    display: flex;
    flex-direction: row;
  }

  .card-eventos {
    display: flex;
    width: 100%;

    .imagem-evento {
      min-width: 400px;
    }

    .evento-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;

      .col {
        padding-left: 0;
      }
    }
  }

  .evento-do-dia-header {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;

    p {
      @include label-normal-sans-serif-1;
    }
    h6 {
      text-transform: none;
      @include label-normal-sans-serif-1;
      color: $bg-dark-4;
    }
  }

  .tipografia-container {
    @include body-large-serif;
    text-align: lef;
    padding-bottom: 0.312rem;
  }

  .css-ecpxie-MuiTypography-root {
    margin: 0;
    @include body-normal-sans-serif;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);
  }

  .evento-do-dia-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 10px;

    .evento-data {
      display: flex;
      flex-direction: column;
      @include body-normal-serif;
      .evento-horas-data {
        display: flex;
        &::before {
          padding-right: 30px;
          padding-bottom: 5px;
          content: "";
          background-image: url("../../../assets/img/expo-revestir/icons/relogio.svg");
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          filter: invert(1);
        }
      }

      .evento-calendario-data {
        display: flex;
        align-items: center;
        &::before {
          padding-right: 30px;
          padding-bottom: 5px;
          content: "";
          background-image: url("../../../assets/img/expo-revestir/icons/calendario.svg");
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
        }
      }

      p {
        @include body-medium-serif;
        line-height: 120% !important;
        color: $bg-dark-3;
      }
    }
    .button-agenda {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }

  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    border-radius: 0px;
    overflow: hidden;
    display: block;
  }

  .css-1stbitf-MuiCardMedia-root {
    display: block;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-position: center;
    background-position: center;
    width: 100%;
    object-fit: cover;
  }
}

.venha-ver-container {
  width: 100%;
  background-image: url(../../img/expo-revestir/images/venha_ver.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;

  .infos {
    min-height: 700px;
    padding: 5% 0 2% 46%;
    padding: 40px;
    width: 50%;
    background-color: #1e46ff;

    .texto {
      width: 80%;

      h1 {
        @include display-2-serif;
        color: $bg-light-1;
        margin-bottom: 1rem;
      }

      h3 {
        color: $bg-light-1;
      }
    }

    button {
      cursor: pointer;
      @include body-xlarge-serif;
      padding: 0.6rem 6rem;
      color: $main-color-3;
      background-color: $bg-light;
      margin-top: 1rem;
    }
  }
}

.calendario-eventos-expositor-container {
  h2 {
    @include headline-4-sans-serif;
    color: $bg-dark-1;

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-image: url(../../img/expo-revestir/icons/Arrow2.svg);
      background-repeat: no-repeat;
      background-position: center;
      padding: 0px 1.25rem 0.312rem 3.75rem;
    }
  }
}

.principal-direito-main {
  p {
    @include body-xlarge-sans-serif;
    color: $main-color-3;
    background-color: $bg-light;
  }
}

.data-evento {
  background-color: #f2f2f5;
  position: absolute;
  width: auto;
  height: auto;
  padding: 10px 20px;
  p {
    @include body-medium-serif;
    text-align: center;
    margin: 0;
    font-weight: bold;
  }
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
  // primeiro container (Explore tendências, contrua sonhos)
  .paragrafo-container {
    font-size: 1.6em;
  }

  // segundo container (Lançamentos da edição)
  .lancamento-edicao-container {
    .lancamento-itens {
      width: 78%;
    }
  }

  // eventos do dia
  .venha-ver-container {
    .infos {
      .texto {
        h1 {
          font-size: 3.5em;
          line-height: 70px;
        }

        h3 {
          @include body-large-sans-serif;
        }
      }
    }
  }

  .card-eventos-container {
    flex-direction: column;
    .col-xl-6 {
      max-width: 80%;
      margin: 20px auto;
    }
    .borda-evento {
      cursor: pointer;
      width: 100%;
    }

    .card-eventos {
      display: flex;
      width: 100%;

      .evento-info {
        .col {
          padding-left: 0;
        }
      }
    }
    .evento-do-dia-footer {
      flex-direction: row;
    }
  }

  .btn-cta-home-dark {
    h3 {
      font-size: 0.75em;
      margin: 0px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  // primeiro container (Explore tendências, construa sonhos)
  .main-page-container {
    .title-container {
      h1 {
        font-size: 6em !important;
      }
    }

    .paragrafo-container {
      h3 {
        font-size: 1.45rem;
      }
    }

    .cards-container {
      .bg-card {
        p {
          font-size: 1.2em;
          padding: 0.5rem;
        }
      }
    }
  }

  // segundo container (Lançamentos da edição)
  .lancamento-edicao-container {
    .lancamento-itens {
      width: 65%;

      .texto {
        @include headline-3-sans-serif;
      }
    }
  }

  // eventos do dia
  .venha-ver-container {
    .infos {
      .texto {
        h1 {
          font-size: 3.5em;
          line-height: 55px;
        }

        h3 {
          @include body-large-sans-serif;
        }
      }

      button {
        margin-top: 2rem;
        padding: 0.6rem 3rem;
        font-size: 1.5em;
      }
    }
  }

  .card-eventos-container {
    .borda-evento {
      width: 100%;
      flex-direction: column;
    }

    .card-eventos {
      display: flex;
      width: 100%;

      .evento-info {
        .col {
          padding-left: 0;
        }
      }
    }
    .evento-do-dia-footer {
      align-items: flex-end;
      flex-direction: row;
    }
  }

  .btn-cta-home-dark {
    h3 {
      font-size: 0.75em;
      margin: 0px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  // primeiro container (Explore tendências, construa sonhos)
  .main-page-container {
    .title-container {
      h1 {
        font-size: 5em !important;
        line-height: 75px;
      }
    }

    .paragrafo-container {
      h3 {
        font-size: 1.45rem;
      }
    }

    .cards-container {
      .bg-card {
        p {
          font-size: 1.2em;
          padding: 0.2rem;
        }
      }
    }
  }

  // segundo container (Lançamentos da edição)
  .lancamento-edicao-container {
    .lancamento-itens {
      width: 65%;

      .texto {
        @include headline-4-sans-serif;
      }

      .botao-container {
        margin-top: 1rem;
      }
    }
  }

  // eventos do dia
  .venha-ver-container {
    .infos {
      .texto {
        h1 {
          @include headline-4-sans-serif;
        }

        h3 {
          @include body-large-sans-serif;
        }
      }

      button {
        margin-top: 1rem;
        padding: 0.3rem 2rem;
        font-size: 1.3em;
      }
    }
  }

  .card-eventos-container {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;

    .borda-evento {
      width: 100%;
      flex-direction: column;
    }

    .card-eventos {
      display: flex;
      width: 100%;
      margin-bottom: 1rem;

      .evento-info {
        .col {
          padding-left: 0;
        }
      }
    }
    .evento-do-dia-footer {
      align-items: flex-end;
      flex-direction: row;
    }
  }

  .btn-cta-home-dark {
    h3 {
      font-size: 0.75em;
      margin: 0px;
    }
  }
}

@media (max-width: 767px) {
  // primeiro container (Explore tendências, construa sonhos)
  .main-page-container {
    .title-container {
      margin: 0px;
      font-size: 0.5rem;

      h1 {
        line-height: 55px;
        font-size: 6em !important;
      }
    }

    .paragrafo-container {
      font-size: 1.2em;
      line-height: 30px;
      padding: 0;

      h3 {
        font-size: 0.8em;
      }
    }

    .cards-container {
      padding: 1rem 0rem;
      display: block;

      .bg-card {
        width: 70%;
        margin: 16px auto;

        p {
          font-size: 0.8em;
          padding: 1rem;
          line-height: 14px;
        }
      }

      img {
        width: 90px;
      }

      h3 {
        font-size: 1.7em;
        margin-bottom: 0.5rem;
        margin-top: 1.3rem;
        color: $main-color-3;
      }

      p {
        @include body-normal-sans-serif;
        color: $bg-dark-3;
      }
    }
  }

  // segundo container (Lançamentos da edição)
  .lancamento-edicao-container {
    padding: 2.75rem 0px;
    .lancamento-itens {
      width: 100%;

      .texto {
        @include body-xlarge-sans-serif;
      }

      .botao-container {
        margin-top: 1rem;
      }
    }
  }

  // eventos do dia
  .venha-ver-container {
    background-image: none;
    background-color: $main-color-3;
    padding: 4rem 1rem 0rem 1rem;

    .infos {
      padding: 0;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      min-height: 0px;

      .texto {
        margin: 0 auto;
        h1 {
          @include headline-4-sans-serif;
        }

        h3 {
          @include body-medium-serif;
        }
      }

      button {
        margin-top: 1rem;
        padding: 0.3rem 2rem;
        font-size: 1.3em;
      }
    }
  }

  .eventos-do-dia-title-container h1 {
    font-size: 2em;
  }

  .card-eventos-container {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    .col-xl-6 {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
    .borda-evento {
      width: 100%;
      flex-direction: column;
    }

    .card-eventos {
      display: flex;
      width: 100%;

      .evento-info {
        .col {
          padding-left: 0;
        }
      }
    }
    .evento-do-dia-footer {
      padding-top: 30px;

      button {
        padding: 8px 16px;
      }
    }

    .tipografia-container {
      font-size: 1.5em;
    }
  }

  .calendario-eventos-do-dia-container {
    text-align: center;
    padding: 0 2rem 2rem 1rem;
  }

  .calendario-eventos-do-dia-container h2 {
    font-size: 1.5em;
  }

  .calendario-eventos-do-dia-container h2::after {
    display: none;
  }

  // container eventos gerais
  .main-home-container {
    .principal-container-direito {
      padding-bottom: 2.8125rem;
      .principal-direito-header {
        h1 {
          margin: 2rem 0rem;
          @include headline-2-serif;
        }

        h2 {
          @include headline-4-sans-serif;
        }
      }
    }
  }

  .btn-cta-home-dark {
    padding: 1rem;
    line-height: normal;

    h3 {
      font-size: 0.4em;
      margin: 0px;
    }
  }

  // container de dúvidas
  #acordeon a {
    font-size: 0.8em;
  }
}

.promocao-apoio-imagens {
  text-align: center;
}
