svg {
  overflow: visible;
}

.form-control:focus + .input-group-text {
  border: 1px solid $bg-dark-4;
}

.form-control {
  background-color: $bg-light;
  border: 1px solid $bg-dark-4;
  border-radius: 0px;
  color: $bg-dark-4;
  @include body-normal-sans-serif;
  transition: background-color 0.3s ease 0s;
  padding-right: 0 !important;

  &:focus {
    border: 1px solid $bg-dark-4;
    outline: 0 !important;
    box-shadow: none;

    + .input-group-prepend,
    + .input-group-append {
      .input-group-text {
        border: 1px solid $bg-dark-4;
        border-left: none;
      }
    }
  }

  .has-success & {
    border: 1px solid $success-color-3;
    color: $success-color-3;

    &.form-control-success {
      padding-right: 2.5em !important;
    }
  }
  .has-success &:focus {
    border: 1px solid $success-color-3;
    color: $success-color-3;
  }
  .has-danger & {
    background-color: $bg-light;
    border: 1px solid $danger-color-3;
    color: $danger-color-3;

    &.form-control-danger {
      padding-right: 2.5em !important;
      border-left: 0px;
    }
  }
}

.form-control-login {
  padding: 0px !important;
}

.input-group .input-group-append .input-group-text {
  border-right: 0px;
}

.input-group .form-control:first-child,
.input-group-text:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 1px solid $bg-dark-4;
  border-right: 1px solid $bg-dark-4;
}

.form-control-simple {
  padding: 0.4375rem 0.75rem !important;
  border-right: 1px solid $bg-dark-4 !important;
}

.form-control-danger-simple {
  padding-right: 2.5em !important;
  border: 1px solid $danger-color-3 !important;
}

.input-group-text {
  background-color: $bg-light;
  border-radius: 0px;
  border: 1px solid $bg-dark-4;
  border-right: 0px;
  .has-danger & {
    background-color: $bg-light;
    border: 1px solid $danger-color-3;
    color: $danger-color-3;
  }
}

.input-login-user {
  &::before {
    content: "";
    display: block;
    background-color: transparent;
    background-image: url("../../img/icons/email.svg");
    background-repeat: no-repeat;
    width: 1.5em;
    height: 1.5em;
  }
}

.input-login-password {
  &::before {
    content: "";
    display: block;
    background-color: transparent;
    background-image: url("../../img/icons/lock.svg");
    background-repeat: no-repeat;
    width: 1.5em;
    height: 1.5em;
  }
}

.input-login-user-erro {
  &::before {
    content: "";
    display: block;
    background-color: transparent;
    background-image: url("../../img/icons/email-erro.svg");
    background-repeat: no-repeat;
    width: 1.5em;
    height: 1.5em;
  }
}

.input-login-password-erro {
  &::before {
    content: "";
    display: block;
    background-color: transparent;
    background-image: url("../../img/icons/lock-erro.svg");
    background-repeat: no-repeat;
    width: 1.5em;
    height: 1.5em;
  }
}
