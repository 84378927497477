.modal-body-container {
  background-color: $bg-light;
  padding: 0 !important;

  .container-cadastro-usuarios {
    display: flex;

    .modal-cadastro-erro {
      color: $danger-color-2;
      @include body-normal-sans-serif;
    }

    .imagem-background-cadastro {
      flex: 1;
      background-color: #ff886e;
      background-image: radial-gradient(rgb(0, 0, 0) 25%, transparent 15%),
        radial-gradient(rgb(0, 0, 0) 10%, transparent 15%);
      background-size: 30px 30px;
      background-position: 0 0, 0 0;
    }

    .dados-background {
      padding: 60px 40px;
      flex: 3;

      .input-modal-usuario-empresa {
        width: 100%;
        border: none;
        border-bottom: 2px solid #cac8c8;
        color: gray;
        padding: 5px;

        &:focus-visible {
          outline: none;
        }
      }

      .col-modal-endereco {
        margin: 20px 0px;
      }

      .col-modal-contato {
        margin: 20px 0px;
      }

      .col-modal-cadastro {
        text-align: center;
      }

      .data-nascimento-input {
        width: 100%;
        border: none;
        border-bottom: 2px solid #52cae85b;
        color: gray;
        padding: 5px;

        &:focus-visible {
          outline: none;
        }
      }
    }

    .div-botao-cadastro-novo-usuario {
      text-align: right;
    }
  }
}
