@font-face {
  font-family: Neue Haas Display;
  src: url("../fonts/NeueHaasDisplayRoman.ttf");
}

@import "expo-revestir/variables";
@import "expo-revestir/mixins";
@import "expo-revestir/buttons";
@import "expo-revestir/forms";
@import "expo-revestir/icons";
@import "expo-revestir/ExpositoresListagem";
@import "expo-revestir/navbar";
@import "expo-revestir/footer";
@import "expo-revestir/home";
@import "expo-revestir/BannerInicial";
@import "expo-revestir/HomeCarousel";
@import "expo-revestir/BannerPadrao";
@import "expo-revestir/ExpositorNavBar";
@import "expo-revestir/ListagemProdutos";
@import "expo-revestir/ListagemEventosExpositor";
@import "expo-revestir/ExpositorSobre";
@import "expo-revestir/Badges";
@import "expo-revestir/ExpositoresVideos";
@import "expo-revestir/Catalogos";
@import "expo-revestir/login";
@import "expo-revestir/EventosFuturos";
@import "expo-revestir/breakpoints";
@import "expo-revestir/promocao-contato";
@import "expo-revestir/modalEventos";
@import "expo-revestir/PaginaTransmissaoEvento";
@import "expo-revestir/PefilUsuario";
@import "expo-revestir/ModalEditarUsuario";
@import "expo-revestir/EnderecoFormulario";
@import "expo-revestir/TelefoneFormulario";
@import "expo-revestir/ProdutoCard";
@import "expo-revestir/ModalFaleComExpositor";
@import "expo-revestir/ModalFaleConosco";
@import "expo-revestir/ExpositorRoutes";
@import "expo-revestir/ModalCadastroUsuario";
@import "expo-revestir/AlertMenssage";
@import "expo-revestir/EventosConteudosDisponiveis";
@import "expo-revestir/ModalVerProdutoExpositor";
@import "expo-revestir/CalendarioEventosAgendados";
@import "expo-revestir/DialogRemoverFoto";
@import "expo-revestir/DuvidasPage";

body {
  @include body-normal-sans-serif;
  color: $bg-dark-1;
  &::-webkit-scrollbar {
    width: 7px;
    max-height: 1000px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bg-dark-4;
    border-radius: 100px;
    border: 0px;
  }

  height: 100%;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
a,
.td-name,
td,
button,
input,
select,
textarea {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-system;
  font-weight: normal;
  color: $bg-dark-1;
}

.form-check {
  padding-left: 0;
  margin-bottom: 0.5rem;
}
.form-check .form-check-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 26px;
  margin-bottom: 0;
}
.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  cursor: pointer;
  border-radius: 0px;
  top: 0;
  border: 2px solid $bg-dark-1;
  background-color: transparent;
  -webkit-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  -ms-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}
.form-check .form-check-sign::after {
  font-family: nucleo-icons;
  content: "\ea21";
  top: -1px;
  text-align: center;
  font-size: 15px;
  opacity: 0;
  color: #fff;
  border: 0;
  background-color: inherit;
}
.form-check.disabled .form-check-label {
  color: #9a9a9a;
  opacity: 0.5;
  cursor: not-allowed;
}

.form-check input[type="checkbox"],
.form-check-radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.form-check input[type="checkbox"]:checked + .form-check-sign::after {
  opacity: 1;
}

.form-control input[type="checkbox"]:disabled + .form-check-sign::before,
.checkbox input[type="checkbox"]:disabled + .form-check-sign::after {
  cursor: not-allowed;
}

.form-check
  .form-check-label
  input[type="checkbox"]:disabled
  + .form-check-sign,
.form-check-radio input[type="radio"]:disabled + .form-check-sign {
  pointer-events: none !important;
}

.form-check-label input[type="checkbox"]:checked + .form-check-sign:before {
  background-color: $bg-dark-1;
  border: 2px solid $bg-dark-1;
}

.react-select .react-select__control {
  border-radius: 0px;
  height: 50px;
}

.react-select .react-select__control,
.react-select .react-select__control:hover {
  border: 1px solid #000;
}

.react-select
  .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value {
  top: 48%;
  color: $bg-dark-2;
  @include body-medium-sans-serif;
}

.react-select
  .react-select__control
  .react-select__indicators
  .react-select__dropdown-indicator:after {
  transition: all 150ms ease 0s;
  border-color: transparent;
  border-style: none;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: auto;
  width: auto;
  position: relative;
  content: "\ea39";
  font-family: "nucleo-icons";
  top: 2px;
}

.react-select .react-select__control .react-select__placeholder {
  color: #9a9a9a;
  @include body-medium-sans-serif;
  top: 48%;
}

.react-select
  .react-select__control.react-select__control--is-focused
  .react-select__dropdown-indicator:after {
  transform: rotate(180deg);
  top: 2px;
}

.react-select .react-select__control.react-select__control--is-focused,
.react-select .react-select__control.react-select__control--is-focused:hover {
  border: 1px solid $bg-dark-1;
  box-shadow: none;
}

.react-select {
  .react-select__menu {
    max-width: 100%;
    width: 100%;
    right: 0%;
    border-radius: 0px;
    border: 0;
    box-shadow: 1px 2px 7px 1px rgba(0, 0, 0, 0.125);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      opacity 0.3s ease 0s, height 0s linear 0.35s;
    background-color: $bg-light;
    box-sizing: content-box;
    max-height: 200px;
    position: absolute;
    z-index: 2;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .react-select__option {
    @include body-normal-sans-serif;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-top: 5px;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: block;
    outline: none;
    color: #292b2c;
    &:first-child {
      margin-top: 0;
    }
  }

  .react-select__menu-list {
    max-height: 198px;
    overflow-y: auto;
    padding-bottom: unset;
    padding-top: unset;
    position: relative;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 3px;
      max-height: 198px;
    }

    &::-webkit-scrollbar-track {
      background: $bg-light;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $bg-dark-5;
      border-radius: 20px;
      border: 0px;
    }
  }
}

.dropdown-container {
  .dropdown-content {
    ul {
      font-size: 0.875em;
      font-family: Neue Haas Display;
    }
  }
}

.dropdown-heading-value {
  span {
    font-size: 0.875em;
    font-family: Neue Haas Display;
  }
}

.imagem-banners {
  pointer-events: none;
}

.error-input {
  width: 100%;
  font-size: 12px;
  color: #e91224;
  margin-top: 2px;
  margin-bottom: 10px;
}

.required-hash {
  color: #e91224;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.add-item {
  cursor: pointer;
}

.icone-lixeira-forms {
  display: flex;
  justify-content: flex-end;
  width: 96%;

  i {
    cursor: pointer;
  }
}

.css-26l3qy-menu {
  z-index: 9999 !important;
}

.glAxCh {
  background-color: $bg-dark-1 !important;
  box-shadow: 0 0 1px 3px $bg-dark-1 !important;
}

.fa {
  color: $bg-dark-1;
}

.cursor-pointer {
  cursor: pointer;
}

.container-index {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .content-index {
    flex: 1;
    min-height: calc(100vh - 78px);
  }
}
