.accordion-item {
  cursor: pointer;
}

.accordion-header {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -150px;
  margin-bottom: 12px;
}

.module-info {
  display: flex;
  width: 1290px;
  padding: 50px;
  justify-content: space-between;
  align-items: center;
  background: var(--text-background-light-60, #f2f2f5);
}

.accordion-icon {
  .arrow {
    width: 12px;
    height: 12px;
   
  }
}

.accordion-content {
  a {
    text-decoration: none;
    color: inherit;
  }
}

.container-acorddion {
  max-width: 1290px;
  margin: 0 auto 50px;
}

@media only screen and (max-width: 768px) {
  .container-acorddion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }
  .arrow {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    margin-top: 20px !important;
  } 

  h3 {
    margin-left: 20px;
  }

  .module-info {
    width: 90vw;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    margin-right: 10px;
  }
  .accordion-icon {
    display: flex;
    position: absolute;
    right: 10vw;
    img {
      margin-top: 15px;
    }
  }

  .module-name {
    color: var(--text-background-dark-100, #000);
    font-family: "Roboto Slab", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 29.068px; /* 120% */
  }

  .module-title {
    color: var(--text-background-dark-80, #202020);
    font-family: "Roboto Slab", sans-serif;
    font-size: 14.534px;
    font-style: normal;
    font-weight: 300;
    line-height: 19.379px; /* 133.333% */
  }

  .accordion-header {
    gap: 10px;
  }
}
