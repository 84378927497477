.usuario-nome-container {
  background-color: $bg-light;
  padding: 60px 0px 30px 0px;
  h1 {
    @include headline-4-sans-serif;
    font-weight: bold;
  }

  h4 {
    cursor: pointer;
    display: inline-block;
  }
}

.ultimos-visitados-container-background {
  background-color: $bg-color;
  margin-right: 0 !important;
  h3 {
    @include body-large-serif;
    margin-bottom: 10px;
  }

  .expositores-clicados-card {
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.062));

    img {
      width: 100%;
    }
  }
}

.meus-comprovantes-container {
  background-color: $bg-color;
  padding: 60px 0;
  .meus-comprovantes-title {
    h1 {
      @include headline-4-serif;
    }
    padding-bottom: 2rem;
    text-align: start;
  }

  .text-center {
    text-align: start !important;
  }
  .meus-comprovantes {
    margin-left: 10px;
    .evento-coluna-tabela {
      text-align: center;
      border-width: 1px 0px;
      border-style: solid;
      border-color: #000000;
      padding: 15px;
      margin-right: 20px !important;
    }
    .baixar-comprovante {
      &:first-child {
        margin-left: 2px;
      }
    }
    &:nth-child(odd) {
      .evento-coluna-tabela {
        border-width: 1px 0px;
        border-style: solid;
        border-color: #000000;
        padding: 15px;
        margin-right: 20px !important;
        background-color: $bg-color-line-tab;
      }
    }
  }
}

.favoritos-title-container {
  width: 100%;
  padding-bottom: 10px;
  h2 {
    @include headline-3-serif;
  }
}

.expositores-favoritos-container-background {
  background-color: $bg-light;
  padding: 5em 0px 5em 0px;

  .img-container-background {
    width: 100%;
    background-color: #ffff;
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    padding: 10px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

.produtos-favoritos-container-background {
  background-color: $bg-color;
  padding: 74px 0px 50px 0px;

  .img-container-background {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .usuario-nome-container {
    h1 {
      @include body-xlarge-serif;
    }

    h4 {
      @include body-large-serif;
    }
  }

  .favoritos-title-container {
    padding-bottom: 20px;

    h3 {
      @include headline-4-serif;
    }
  }
}

@media (max-width: 767px) {
  .meus-comprovantes-container {
    padding: 5rem 0;
  }
  .usuario-nome-container {
    h1 {
      @include body-xlarge-serif;
    }

    h4 {
      @include body-large-serif;
    }
  }

  .favoritos-title-container {
    padding-bottom: 20px;

    h2 {
      @include body-xlarge-serif;
    }
  }

  .expositores-favoritos-container-background .img-container-background {
    width: 90%;
    margin: 0.5rem auto;
  }
  .meus-comprovantes {
    .evento-coluna-tabela {
     display: flex;
     justify-content: center;
    }
  }
}
