.cabecalho-calendario {
  display: flex;
  align-items: center;
}

.container-cabecalho-calendario {
  background-color: $bg-dark-1;
  padding: 22px 0;
  margin: 22px 0;
  color: $bg-light;
}

.btn-cabecalho-container {
  background-color: transparent;
  border: 2px solid $bg-dark-1;
  padding: 10px 22px;
  margin-left: 16px;
}

.botao-proximo-calendario {
  z-index: 9;
  background: transparent;
}

.btn-cabecalho-container-full-black {
  background-color: $bg-dark-1;
  border: 2px solid $bg-dark-1;
  padding: 10px 22px;
  color: $bg-light;
  margin-left: 16px;
}

#titulo-calendario {
  @include headline-4-serif;
  text-align: start;
  font-weight: $font-weight-semi;
}

#data-calendario {
  text-align: center;
}

.container-botoes-calendario {
  justify-content: flex-end;
}

.btn-calendario-anterior {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn-calendario-proximo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#seta-esquerda-calendario {
  width: 60px;
  filter: invert(1);
}
.botao-proximo-esquerda {
  background-color: transparent;
}

#seta-direita-calendario {
  width: 60px;
  filter: invert(1);
}
.botao-proximo-direita {
  background-color: transparent;
}

.btn-calendario-ver-evento {
  color: $bg-dark-1;
  border: 1px solid $bg-dark-1;
  padding: 10px 18px;
  margin: 10px 0;
}

.btn-calendario-ver-evento:hover {
  background-color: $bg-dark-1;
  color: $bg-light;
  padding: 10px 18px;
  margin: 10px 0;
}

.btn-calendario-deletar-evento {
  color: $bg-dark-1;
  border: 1px solid $bg-dark-1;
  padding: 10px 18px;
  margin: 10px 0;
}
.btn-calendario-deletar-evento:hover {
  background-color: $bg-dark-1;
  color: $bg-light;
  padding: 10px 18px;
  margin: 10px 0;
}

.tui-full-calendar-popup-detail .tui-full-calendar-section-button {
  display: flex;
  justify-content: space-between;
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
  #titulo-calendario {
    font-size: 26px;
  }

  #data-calendario {
    font-size: 26px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #titulo-calendario {
    font-size: 26px;
  }

  #data-calendario {
    font-size: 26px;
  }

  .tui-full-calendar-dayname-date {
    font-size: 22px !important;
  }

  .tui-full-calendar-dayname-name {
    font-size: 8px !important;
  }
}

@media (max-width: 767px) {
  #titulo-calendario {
    font-size: 22px;
  }

  #data-calendario {
    font-size: 22px;
  }

  #seta-esquerda-calendario {
    width: 40px;
  }

  #seta-direita-calendario {
    width: 40px;
  }

  .tui-full-calendar-dayname-date {
    font-size: 22px !important;
  }

  .tui-full-calendar-dayname-name {
    font-size: 8px !important;
  }
}
