.container-page {
  display: flex;
  margin: 5% 5%;

  .cabecalho {
    display: flex;
  }

  .box-luz {
    display: flex;
    justify-content: flex-end;

    .icon-luz {
      font-size: 2rem;
      cursor: pointer;
    }
  }

  h1 {
    color: #000;
    font-family: "Raleway", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 120% */
    margin-bottom: 50px;
  }
  p {
    color: #000;
    font-family: "Raleway", sans-serif;
  }

  .modulo-title {
    h1 {
      margin-bottom: 0 !important;
      color: #000;

      /* Display & headlines - sans-serif/Headline 4 - Sans serif - Regular */
      font-family: "Raleway", sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 120% */
    }

    h4 {
      color: #000;

      /* Body - serif - regular & bold/Body Medium - Serif - Thin */
      font-family: "Roboto Slab", sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px; /* 133.333% */
    }
  }

  .info-progresso-curso {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: -10px;

    .icon-clock {
      display: flex;
      gap: 5px;
      i {
        font-size: 1.4rem;
        margin-right: 5px;
      }
    }
  }
}

.container-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;

  button {
    display: inline-flex;
    padding: 20px 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid #8c8c8c;
  }

  button:disabled {
    opacity: 0.3;
  }
}

@media only screen and (max-width: 768px) {
  .container-page {
    flex-direction: column;
    margin: 5% 2%;
  }

  .cabecalho {
    margin-bottom: 20px;
    margin-left: 0;
    padding: 0;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .modulo-title h1 {
    font-size: 32px !important;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .modulo-title h4 {
    font-size: 18px;
    line-height: 24px;
  }

  .container-buttons {
    flex-direction: column; /* Altera para uma disposição de coluna em telas menores */
    align-items: center; /* Centraliza os itens na coluna */
    gap: 10px; /* Adiciona um espaçamento entre os botões */
  }

  .container-buttons button {
    padding: 15px 40px; /* Reduz o preenchimento dos botões */
  }
}

.container-video-vimeo {
  padding: 30px 0;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.apagar-luz {
  margin: -20px 0;
  padding: 20px 0;
  background-color: #000;

  .modulo-title h1,
  h4 {
    color: #fff !important;
  }

  .cabecalho a p,
  p {
    color: #fff !important;
  }

  .cabecalho img {
    filter: invert(100%);
  }

  .box-luz {
    .icon-luz {
      color: #fff;
    }
  }

  button {
    color: #fff;
    background-color: transparent;
  }
}
