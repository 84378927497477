.modal-fale-conosco {
  .modal-content {
    .modal-header {
      background-color: $bg-color !important;
    }
    background-color: $bg-color;
    .container-texto {
      textarea {
        width: 100%;
        height: 30vh;
        padding: 12px;
        outline: none;
      }
    }

    .fale-conosco-button-container {
      padding-top: 1rem;
    }

    .modal-fale-conosco-button-submit {
      margin-right: 10px;
      background-color: #60269e;
      border: 3px solid #60269e;
      color: #fff;
      &:hover {
        background-color: #491381 !important;
        border: 3px solid #491381 !important;
      }
    }
  }
}
