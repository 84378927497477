.image-input-preview {
  input {
    display: none;
  }

  .image-profile {
    width: 245px;
    height: 200px;
    border: 2px solid #f87fb4;

    .icon {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      position: absolute;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-left: 5px;
      margin-top: 5px;
      overflow: hidden;
      i {
        font-size: 2em;
        background-color: #ffffff85;
        width: 100%;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s;
      }
      i:hover {
        background-color: #d2d6de;
      }
    }
  }
}

.foto-usuario {
  text-align: center;
  margin-top: 25px;

  .alterar-foto {
    padding-top: 20px;
    color: #f87fb4;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
  }
  .alterar-foto:hover {
    color: #e2156e;
    transform: translateY(-2px);
  }

  .remover-foto {
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
    font-size: 12px;
  }
  .remover-foto:hover {
    color: #3f3f3f;
    transform: translateY(-2px);
  }

  .imagem-atualizada-label {
    color: #0edeac;
  }
}
