.produto-individual-container {
  margin-bottom: 5rem;

  .modal-ver-produto-imagem-slide {
    cursor: pointer;
  }

  .voltar-galeria {
    p {
      cursor: pointer;
      font-size: 1.5rem;

      &::before {
        content: "";
        width: 1px;
        height: 10px;
        background-image: url(../../img/expo-revestir/icons/Arrow1.svg);
        background-repeat: no-repeat;
        background-position: center;
        padding: 0rem 1rem;
      }
      &:hover {
        color: $main-color-3;
      }
    }
  }

  .informacao-produto-header {
    .titulo-e-favoritar-produto-container {
      align-items: flex-start;

      .titulo-produto-container {
        h1 {
          @include headline-3-serif;
          font-weight: bold;
          color: #000;
        }
      }

      .favoritar-coracao-col {
        text-align: right;
        .favoritar-coracao-imagem {
          width: 80%;
          text-align: right;
        }
      }

      .favoritar-pointer-produto {
        width: 75%;
      }

      img {
        cursor: pointer;
      }
    }

    p {
      @include body-large-serif;
      color: #000;
    }
  }

  .informacao-secundaria-container {
    h5 {
      @include body-medium-serif;
      color: #000;

      span {
        font-weight: bold;
      }
    }
  }

  .informacao-imagem-container {
    h5 {
      @include body-large-serif;
      color: #000;
      font-weight: bold;
    }

    p {
      @include body-medium-serif;
      color: #000;
    }
  }

  .ver-produto-link-container {
    button {
      padding: 1em !important;
      background-color: $bg-color;
      transition: 0.2s;

      a {
        @include body-medium-serif;
        color: $bg-dark-1;
        text-decoration: none;
      }

      &:hover {
        a {
          color: $bg-light-1;

          img {
            filter: invert(1);
          }
        }
        background-color: $bg-dark-1;
      }
    }
  }

  .btn-anterior-proximo-container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;

    .btn-produto-card {
      background-color: transparent;
      border: 2px solid $bg-dark-1;
      padding: 10px 30px;

      &:hover {
        background-color: $bg-dark-1;
        color: $bg-light;

        img {
          filter: invert(1);
        }
      }
    }
  }
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .produto-individual-container {
    .informacao-produto-header {
      .titulo-e-favoritar-produto-container {
        .titulo-produto-container {
          h1 {
            @include headline-4-serif;
            font-weight: bold;
          }
        }

        .favoritar-coracao-imagem {
          width: 40%;
        }
        .favoritar-pointer-produto {
          width: 75%;
        }
      }

      p {
        @include body-medium-serif;
      }
    }

    .informacao-secundaria-container {
      h5 {
        @include body-normal-serif;
      }
    }

    .informacao-imagem-container {
      h5 {
        @include body-medium-serif;
      }
      p {
        @include body-normal-serif;
      }
    }

    .ver-produto-link-container {
      padding-top: 2rem;

      a {
        @include body-normal-serif;
        text-decoration: none;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .produto-individual-container {
    .informacao-produto-header {
      .titulo-e-favoritar-produto-container {
        .titulo-produto-container {
          h1 {
            @include headline-4-serif;
            font-weight: bold;
          }
        }

        .favoritar-coracao-col {
          .favoritar-coracao-imagem {
            width: 60%;
          }
        }

        .favoritar-pointer-produto {
          width: 50%;
        }
      }

      p {
        @include body-medium-serif;
      }
    }

    .informacao-secundaria-container {
      h5 {
        @include body-normal-serif;
      }
    }

    .informacao-imagem-container {
      h5 {
        @include body-medium-serif;
      }
      p {
        @include body-normal-serif;
      }
    }

    .ver-produto-link-container {
      padding-top: 2rem;

      a {
        @include body-normal-serif;
        text-decoration: none;
      }
    }
  }

  .rec.rec-arrow {
    font-size: 40px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .produto-individual-container {
    .informacao-produto-header {
      padding-top: 4rem;

      .titulo-e-favoritar-produto-container {
        .titulo-produto-container {
          h1 {
            @include body-xlarge-serif;
            font-weight: bold;
          }
        }

        .favoritar-coracao-col {
          .favoritar-coracao-imagem {
            width: 25%;
          }
        }

        .favoritar-pointer-produto {
          width: 20%;
        }
      }

      p {
        @include body-medium-serif;
      }
    }

    .informacao-secundaria-container {
      h5 {
        @include body-normal-serif;
      }
    }

    .informacao-imagem-container {
      padding-top: 1rem;
      h5 {
        @include body-medium-serif;
      }
      p {
        @include body-normal-serif;
      }
    }

    .ver-produto-link-container {
      padding-top: 1rem;

      a {
        @include body-normal-serif;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .produto-individual-container {
    .informacao-produto-header {
      padding-top: 4rem;

      .titulo-e-favoritar-produto-container {
        .titulo-produto-container {
          h1 {
            @include body-large-serif;
            font-weight: bold;
          }
        }

        .favoritar-coracao-col {
          .favoritar-coracao-imagem {
            width: 65%;
          }
        }
        .favoritar-pointer-produto {
          width: 55%;
        }
      }

      p {
        @include body-normal-serif;
      }
    }

    .informacao-secundaria-container {
      h5 {
        @include body-normal-serif;
      }
    }

    .informacao-imagem-container {
      display: none;
    }

    .ver-produto-link-container {
      padding-top: 1rem;

      a {
        @include body-normal-serif;
        text-decoration: none;
      }
    }
  }
}

.rec.rec-arrow:disabled {
  display: none;
}

.rec.rec-arrow {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  font-size: 65px;
  position: absolute;
  z-index: 20;
  color: #fff;
}
button.sc-bdVaJa.jsGVec.rec.rec-arrow.rec.rec-arrow-right {
  right: 20px;
}

.jsGVec:hover:enabled,
.jsGVec:focus:enabled {
  color: #000 !important;
  background-color: transparent !important;
  box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0) !important;
}
