.container-header-cursos {
  background: var(--Azul-AC, #0079c0);

  .container-colunas {
    display: flex;
    padding: 0 8%;
    .coluna-esquerda {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .cabecalho {
        // display: flex;
        // align-items: baseline;
        // text-align: center;
        // gap: 10px;
        // align-self: stretch;
        // margin-top: 56px;
        // margin-bottom: 56px;
        p {
          color: var(--text-background-light-20, #fff);

          /* Body - sans serif - regular & bold/Body Small - Sans serif - Bold */
          font-family: "Raleway", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
        }
      }

      .container-title-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 56px;

        .info-title h3 {
          color: #fff !important;
          font-family: "Raleway", sans-serif;
          font-size: 50px;
          font-style: normal;
          font-weight: 400;
          line-height: 58px;
        }
        .container-cards {
          width: 100%;
          margin-top: 60px !important;
          margin-left: unset;
          display: flex;
          flex-direction: row !important;
          align-items: flex-end;
          flex-wrap: wrap;
          
          .card-info {
            display: flex;
            gap: 23px;
            height: 95px;
            flex: 1
          }

          .card-progress {
            flex: 1
          }
        }
      }
    }

    .coluna-direita {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 60px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container-header-cursos {
    display: flex;
    width: 100vw;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
  }

  .container-colunas {
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }

  .coluna-esquerda {
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin: 0;
    padding: 0;
  }
  .container-title-card {
    margin-bottom: 30px !important;
  }
  .coluna-direita {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .info-title h3 {
    color: var(--text-background-light-20, #fff);
    font-family: "Roboto Slab";
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    margin-bottom: 20px;
  }

  .container-cards {
    display: flex;
    align-items: flex-start;
    gap: 23px;
    align-self: stretch;
    margin: 0 !important;
  }
}
