.produto-loading-container {
  width: 100%;
}
.container-produto {
  .produto-col {
    padding: 0px 5px;
  }
  .produto-card {
    height: 480px;
    margin-top: 0.437rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.produto-card-body {
  background-color: $bg-color-variant-white;
  padding: 30px;

  h4 {
    font-weight: bold;
  }
  .favoritar-produto {
    display: flex;
    align-content: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 50%;
  }
  .dimensao-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    img{
      width: 25px;
      cursor: pointer;
    }
  }

  .botao-produto-container {
    display: flex;
    justify-content: flex-end;
    max-height: 42px;
    white-space: nowrap;
  }
}
#subir-button {
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 99999;
}

.voltar-galeria {
  p {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background-image: url(../../img/expo-revestir/icons/Arrow1.svg);
      background-repeat: no-repeat;
      background-position: center;
      padding: 0rem 1rem;
    }
  }
}

.container-produto-individual {
  display: flex;
  margin-bottom: 60px;

  .container-produto-image {
    flex: 1.6;
  }

  .container-produto-info {
    flex: 1;
    .produto-info {
      padding-left: 1.25rem;

      h1 {
        @include headline-2-serif;
      }

      h4 {
        @include body-large-serif;
        font-weight: bold;
      }

      p {
        @include body-large-sans-serif;
      }

      .favoritar-produto {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin: 80px 0 0 0;
        img {
          width: 20px;
          margin-right: 20%;
        }

        p {
          height: 0;
        }
      }
    }
  }
}

#sentinela-produtos {
  width: 100%;
  height: 80px;
}

@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .container-produto {
    .produto-card {
      height: 400px;
    }
  }

  .dimensao-container {
    p {
      font-size: 14px;
      margin: 0px;
    }
  }

  .botao-produto-container {
    button {
      padding: 0.425rem 0.5rem;
      border: 2px solid black;
      max-height: 42px;
      white-space: nowrap;
      span {
        font-size: 1em;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container-produto {
    .produto-card {
      height: 380px;
    }
  }

  .dimensao-container {
    p {
      font-size: 14px;
      margin: 0px;
    }
  }

  .botao-produto-container {
    button {
      padding: 0.425rem 0.5rem;
      border: 2px solid black;
      max-height: 42px;
      white-space: nowrap;

      span {
        font-size: 0.9em;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container-produto {
    .produto-card {
      height: 300px;
    }
  }

  .dimensao-container {
    p {
      font-size: 14px;
      margin: 0px;
    }
  }

  .botao-produto-container {
    button {
      padding: 0.425rem 0.5rem;
      border: 2px solid black;
      max-height: 42px;
      white-space: nowrap;

      span {
        font-size: 0.9em;
      }
    }
  }

  .favoritar-produto {
    h4 {
      font-size: 18px;
    }
  }

  .produto-card-body {
    p {
      font-size: 12px;
    }
  }
}

@media (max-width: 767px) {
  .container-produto {
    .produto-card {
      height: 300px;
    }

    .produto-col {
      padding: 0px 15px;
    }
  }

  .dimensao-container {
    p {
      font-size: 14px;
      margin: 0px;
    }
  }

  .botao-produto-container {
    button {
      padding: 0.225rem 0.5rem;
      border: 2px solid black;
      max-height: 42px;
      white-space: nowrap;

      span {
        font-size: 0.9em;
      }
    }
  }

  .favoritar-produto {
    h4 {
      font-size: 18px;
      margin: 0px;
    }
    img{
      width: 20px;
    }
  }

  .produto-card-body {
    background-color: $bg-color;
    p {
      font-size: 12px;
    }
  }
}
