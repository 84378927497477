//Tipografia
$font-system: "Neue Haas Display", sans-serif;

$font-size-base: 16px !default;
$font-size-small: 14px !default;
$font-size-medium: 18px !default;
$font-size-large: 20px !default;
$font-size-large-navbar: 22px !default;

$font-size-h1: 3.6em !default;
$font-size-h2: 2.8em !default;
$font-size-h3: 1.825em !default;
$font-size-h4: 1.6em !default;
$font-size-h5: 1.35em !default;
$font-size-h6: 0.9em !default;
$font-paragraph: 16px !default;
$font-size-navbar: 16px !default;

$font-size-info-horizontal: 2.5em !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semi: 500 !default;
$font-weight-bold: bold !default;
$font-weight-super-bold: 700 !default;

$line-height-small: 20px !default;
$line-height-general: 1.5em !default;
$line-height: 36px !default;
$line-height-lg: 54px !default;

//Cores
$bg-dark-1: #000;
$bg-dark-2: #202020;
$bg-dark-3: #505050;
$bg-dark-5: #e1e1e1;
$bg-dark-4: #8c8c8c;
$bg-light: #fff;
$bg-light-1: #e4e4eb;
$bg-light-2: #ebebf0;
$bg-light-3: #f2f2f5;

$success-color-1: #009974;
$success-color-2: #0edeac;
$success-color-3: #55f2cc;
$success-color-4: #a6ffe9;
$success-color-5: #e5fff9;

$danger-color-1: #a60000;
$danger-color-2: #dd0000;
$danger-color-3: #f24949;
$danger-color-4: #ff8080;
$danger-color-5: #ffe5e5;

$main-color-1: #ffff00;
$main-color-2: #f485c6;
$main-color-3: #2246ff;
$main-color-4: #8de600;
$main-color-5: #ff6e00;
$main-color-6: #ff4100;

$bg-color: #F9F4F1;
$bg-box: #F8F8F8;
$bg-color-variant-white: #FFFFFFd1;
$bg-color-line-tab: #D9D9D9;
$main-color-3: #1e46ff;
$main-color-4: #8DE600;
$main-color-5: #FF6E00;
$main-color-6: #FF4100;
$main-color-7: #9565EF;
$bg-color: #f9f4f1;
$bg-color-variant-white: #ffffffd1;
