.card-palestrante {
  display: flex;
  align-items: center;
  gap: 52px;
}

.imagem-palestrante {
  display: flex;
  img {
    width: 173px;
    height: 173px;
    border-radius: 50%;
  }
}

.info-palestrante {
  h3 {
    color: #202020;
    font-family: "Roboto Slab", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
  }

  p {
    color: var(--text-background-dark-80, #202020);

    /* Body - sans serif - regular & bold/Body Regular - Sans serif - Regular */
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
  }
}

.icon-wrapper {
  width: 173px;
  height: 173px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .user-icon {
    width: 100px; 
    height: 100px; 
  }
}




@media only screen and (max-width: 768px) {
  .card-palestrante {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .info-palestrante {
    width: auto;
    text-align: center;
  }
  .card-palestrante {
    display: flex;
   
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .imagem-palestrante {
    img {
      width: 120px;
      height: 120px;
    }
  }

  .info-palestrante h3 {
    color: #202020;
    font-family: "Roboto Slab", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
  }

  .info-palestrante p {
    color: var(--text-background-dark-80, #202020);
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
  }
}


