.login_wrapper {
  background-color: $bg-dark-1;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  overflow: hidden;

  .container-visitante {
    // background-image: url(../../img/expo-revestir/images/bg-visitante.svg);
    background-image: url(../../img/expo-revestir/bg-login.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .container-login {
    width: 100%;
  }

  .row {
    justify-content: center;
    align-items: center;
  }

  .row-right {
    img {
      position: absolute;
      width: 66%;
    }
  }

  .error-menssage {
    margin-top: 10px;
    @include label-normal-sans-serif;
    color: $main-color-6;
  }
}

.login-bg-right {
  background-color: #000;
  .bg-right {
    padding: 80px 0;
  }
}

.login_text_form {
  color: $bg-dark-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bg-light;
  padding: 6% 5%;
  width: 70%;
  margin: 0 auto;

  .row-perfil {
    width: 100%;
    padding: 20px 0 0px 0px;
    margin: 0 auto;

    .col-perfil {
      padding: 2px;
    }
  }

  .p-button-icon {
    margin-top: -5px;
  }

  .aviso-nao-encontramos-email {
    max-width: 400px;

    h2 {
      @include body-medium-sans-serif;
    }

    h3 {
      @include body-normal-sans-serif;
      padding: 2rem;
      a {
        color: $main-color-2;
        font-weight: bold;
      }
    }
  }

  .login_text {
    z-index: 1;
    h1 {
      @include headline-4-serif;
      margin: 0 0 10px 0;
    }
    p {
      @include body-medium-sans-serif;
      margin: 0 auto;
      max-width: 80%;
      text-align: center;
    }
  }

  .row {
    justify-content: center;
    .form-group {
      text-align: left;
      margin-bottom: 0px !important;

      label {
        @include body-normal-sans-serif;
        margin: 0;
      }

      .error-input {
        padding: 5px 0;

        .error {
          color: $danger-color-2;
          @include body-normal-sans-serif;
        }
      }
      .form-control {
        @include body-normal-sans-serif;

        &:focus-visible {
          outline: 0;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.login_logo {
  height: 100vh;
  display: flex;

  a {
    display: block;
    margin: 0 auto;
    width: 55%;
  }

  img {
    width: 100%;
    padding: 80px 0;
  }
}

.info-pass {
  padding: 5px 0;
  color: #000;
  text-align: left;
  .title-list {
    @include label-normal-sans-serif;

    h6 {
      font-size: 12px;
      line-height: 140%;
      font-weight: bold;
    }
  }

  .check-text {
    margin-right: 19px;
  }

  .nc-icon {
    margin-right: 10px;
  }

  .check-success {
    color: #0f5132;
  }
}

.text-link {
  padding: 10px 0;
  cursor: pointer;
  color: #000;

  span {
    color: $bg-dark-1;
    font-weight: bold;

    &:hover {
      color: $main-color-2;
    }
  }
}
