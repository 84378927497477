.bg-danger {
  background: none;
}

.fixed-top {
  position: static;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  h1 {
    color: $bg-light;
  }
}

.dropdown-menu {
  border-radius: 0%;
  padding: 0;
  border: none;

  .dropdown-item {
    color: $bg-dark-1 !important;
  }
}

.dropdown-menu-right {
  right: 0;
  left: 0;
}

.dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown-menu.dropdown-warning .dropdown-item:hover,
.dropdown-menu.dropdown-warning .dropdown-item:focus {
  background-color: $main-color-2;
  color: $bg-dark-1 !important;
}

.dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.dropdown > .dropdown-menu:first-of-type,
.dropup > .dropdown-menu:first-of-type,
.bootstrap-select > .dropdown-menu:first-of-type {
  z-index: 9999999;
}

.nav-link .profile-photo-small {
  width: 40px;
  height: 40px;
  margin: 0;
}

.navbar {
  .navbar-nav {
    justify-content: flex-end;
    align-items: center;
  }

  .navbar-nav .nav-item .nav-link {
    margin: 15px 3px;
    padding: 0 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    opacity: 1;
    @include body-normal-sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: $bg-dark-1;
  }

  background: $bg-dark-1;
  height: 11.25rem;
  box-shadow: none;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    @include headline-4-serif;
    margin-top: 1.625rem;
  }

  .nav-itens-container {
    width: 100%;
    height: 5.625rem;
    background-color: $bg-color;
    display: flex;
    z-index: 100;

    .row {
      width: 100%;
      align-items: center;
    }

    .dropdown-toggle::before {
      content: "\ea2e";
      font-family: nucleo-icons;
      border-top: none;
      vertical-align: -7%;
      margin-right: 5px;
      color: $bg-dark-1;
      font-size: 1em;
    }

    .dropdown-toggle::after {
      content: "\ea3b";
      font-family: nucleo-icons;
      border-top: none;
      vertical-align: 0%;
      font-size: 10px;
      color: $bg-dark-1;
    }

    .nav-link {
      padding: 0rem 0rem 0rem 0rem;
    }

    li {
      list-style: none;
      @include body-medium-sans-serif;

      i::before {
        font-size: 1em;
      }
    }

    .social-media-container {
      display: flex;
      flex-direction: row;
      li {
        margin: 0 0.6rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .nav-itens {
      width: 100%;
    }

    .menu-parcial-direito {
      padding-top: 0.937rem;
    }
    img {
      width: 120px;
      margin-bottom: 90px;
      overflow: visible;
    }
  }

  .img-circle {
    background-color: $bg-dark-1;
    margin-bottom: 10px;
    padding: 2px;
    border-radius: 0% !important;
    max-width: 100%;
  }

  .usuario-foto-container {
    width: 55px;
    height: 55px;
    border: 0.5px solid #000;
    cursor: pointer;
    text-align: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.navbar .navbar-brand {
  padding: 20px 0px;
}

.menu-mobile {
  position: fixed !important;
  background: $bg-color;
  bottom: 0 !important;
  overflow: hidden !important;
  z-index: 1049;
  width: 100%;
  
  .container {
    margin: 30px auto;

    img {
      margin: 0;
    }
  }

  .menu-mobile-click {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 0;
    background-color: transparent;

    p {
      margin: 0 5px 0 0;
      @include body-large-sans-serif;
      font-weight: bold;
    }

    img {
      width: auto;
    }
  }
}

.hidden-menu-abre {
  padding: 40px 0;
  z-index: 99999999;
  background-color: $bg-color;
  overflow: hidden !important;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 110vh;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 10s;
  animation-name: slideIn;
  animation-duration: 0.6s;

  .navbar-nav {
    .dropdown {
      padding: 0.5rem 0;
    }
    .img-circle {
      background-color: $bg-dark-1;
      margin-bottom: 10px;
      padding: 2px;
      border-radius: 0% !important;
      max-width: 100%;
    }

    .social-media-container {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      li {
        margin: 0 0.6rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  li {
    list-style: none;
    margin-top: 15px;
    text-transform: uppercase;
    @include body-medium-sans-serif;
    
    i::before {
      font-size: 1em;
    }
  }

  .dropdown.show .dropdown-menu {
    display: block !important;
    position: static !important;
    float: none !important;
    background: $bg-light !important;
  }

  .nav-link .profile-photo-small {
    width: 60px;
    height: 60px;
    margin: 0;

    img {
      width: 100%;
      background-color: transparent;
      border: 1px solid #000;
    }
  }
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    left: 2000px;
  }
  to {
    left: 0px;
  }
}

@keyframes slideIn {
  from {
    left: 2000px;
  }
  to {
    left: 0px;
  }
}

#myModal {
  display: none;
  padding: 50px;
}

.hidden-menu-fecha {
  -webkit-animation-name: slideOut;
  -webkit-animation-duration: 10s;
  animation-name: slideOut;
  animation-duration: 0.6s;
}

/* Add Animation */
@-webkit-keyframes slideOut {
  from {
    top: 0px;
  }
  to {
    top: 2000px;
  }
}

@keyframes slideOut {
  from {
    top: 0px;
  }
  to {
    top: 2000px;
  }
}

.dropdown-perfil {
  border-radius: 0px !important;
  left: -148%;
  top: 110%;
}
