#expositor-catalogos {
  padding-bottom: 180px;

  h1 {
    text-align: center;
    padding-bottom: 2.3rem;
    @include body-large-sans-serif;
  }
  
  .catalogos {
    cursor: pointer;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    .catalogo {
      width: 250px;
      transition: all 0.2s;
      &:hover {
        -webkit-box-shadow: 1px 3px 17px -7px #000000;
        box-shadow: 1px 3px 17px -7px #000000;
      }
      .image-card {
        border: 2px solid $main-color-3;
        padding: 10px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 230px;
          object-fit: cover;
        }
      }
      p {
        @include body-medium-sans-serif;
        color: $bg-dark-1;
        padding: 20px;
        text-align: center;
        background-color: $main-color-3;
        margin-bottom: 0px;
      }
    }
  }
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
  #expositor-catalogos {
    h1 {
      font-size: 4.5em;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #expositor-catalogos {
    h1 {
      font-size: 4.5em;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #expositor-catalogos {
    h1 {
      font-size: 4.5em;
    }
  }
}

@media (max-width: 767px) {
  #expositor-catalogos {
    text-align: center;

    h1 {
      font-size: 3.5em;
    }
  }
}
