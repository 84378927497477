.container-card-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 50px 0;;
  .curso-description {
    h3 {
      width: 403px;
      color: var(--text-background-dark-80, #202020);
      font-family: "Roboto Slab", sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 300;
      line-height: 44px;
    }

    p {
      color: var(--text-background-dark-80, #202020);
      font-family: "Raleway", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
}

@media only screen and (max-width: 1112px) {
  .container-card-description {
    width: 100%;
    padding: 0 20px;
  }

  .curso-description h3 {
    width: 100%;
    font-size: 28px;
    line-height: 36px;
  }

  .curso-description p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .container-card-description {
    display: flex;
   
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }

  .curso-description h2 {
    color: var(--text-background-dark-80, #202020);

    /* Body - serif - regular & bold/Body Large - Serif - Thin */
    font-family: "Roboto Slab", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 44px; /* 122.222% */
  }

  .curso-description p {
    color: var(--text-background-dark-80, #202020);

    /* Body - sans serif - regular & bold/Body Regular - Sans serif - Regular */
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
  }
}
