.react-slideshow-wrapper.slide {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-container .default-nav:last-of-type,
.react-slideshow-container .default-nav:first-of-type {
  display: none;
}

.each-slide {
  display: flex;
}

.title-descricao-container {
  h1 {
    @include display-2-serif;
    padding: 13.75rem 1.5rem 0rem 7.5rem;
  }
}

.paragrafo-descricao-container {
  padding: 2.5rem 1.5rem 0rem 7.5rem;
  p {
    @include body-xlarge-sans-serif;
  }
}

.expositor-descricao-container {
  background-color: $bg-dark-1;
  color: $bg-light;
  flex: 1;
}

.dYWSjd {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: block !important;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.iDfeOS {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0px !important;
}

.glAxCh {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  // background-color: black !important;
  font-size: 1.3em;
  content: "";
  // box-shadow: 0 0 1px 3px black !important;
  border-radius: 50%;
  outline: none;
  height: 17px !important;
  width: 17px !important;
}

.fyLrfx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -50px !important;
  z-index: 50;
}

.fSoGyI {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin-left: 13px !important;
  margin-right: 13px !important;
  background-color: white !important;
  box-sizing: 0px !important;
  font-size: 1.3em;
  content: "";
  height: 15px !important;
  width: 15px !important;
  border-radius: 50%;
  outline: none;
}
