.info-card {
  display: flex;
  width: 262px;
  padding: 12px 20px;
  align-items: center;
  gap: 20px;
  background: rgba(0, 0, 0, 0.1);
  .info-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .info-icon img {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
  .info-title {
    margin-bottom: 5px;
    color: var(--text-background-light-20, #fff);

    /* Body - sans serif - regular & bold/Body Small - Sans serif - Bold */
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  .info-info {
    color: var(--text-background-light-20, #fff);

    /* Body - sans serif - regular & bold/Body Regular - Sans serif - Regular */
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
  }
}

@media only screen and (max-width: 768px) {
  .info-card {
    width: 100%;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .info-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;
  }

  .info-icon img {
    width: 25px;
    height: 25px;
  }

  .info-title {
    color: var(--text-background-light-20, #fff);
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
    margin: 0;
  }

  .info-info {
    color: var(--text-background-light-20, #fff);

    /* Labels/Label Medium - Sans serif - Regular */
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}
