#expositor-video {
  line-height: 44px;
  font-size: 1.5em;
  line-height: 1;

  .main {
    h1 {
      @include headline-3-serif;
      text-align: center;
      margin: 60px 0 30px 0;
      line-height: normal;
    }
    .desc {
      margin: 30px 0;
    }
    iframe {
      width: 100%;
      height: 100vh;
    }
  }

  .video {
    margin: 50px 0;
    display: flex;
    .desc {
      flex: 1;
      .titulo {
        @include headline-4-serif;
        margin-bottom: 30px;
      }
    }
    .box-video {
      flex: 2;
      iframe {
        width: 100%;
        height: 600px;
      }
    }

    &:nth-child(even) {
      .desc {
        margin-left: 20px;
        order: 1;
      }
    }

    &:nth-child(odd) {
      .desc {
        margin-right: 50px;
      }
    }
  }

  .container-sem-videos {
    margin: 4% 4%;
  }
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
  #expositor-video {
    .main {
      h1 {
        @include headline-4-serif;
        line-height: normal;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #expositor-video {
    .main {
      h1 {
        @include headline-4-serif;
        line-height: normal;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #expositor-video {
    .video {
      flex-direction: column;
      display: block;

      .desc .titulo {
        font-size: 2em;
      }
      .desc div {
        font-size: 1.5em;
        padding-bottom: 1.5rem;
      }
    }
    .video:nth-child(even) .desc {
      margin-left: 0px;
      order: 1;
    }

    .main iframe {
      width: 100%;
      height: 60vh;
    }

    .main {
      h1 {
        @include body-xlarge-serif;
        line-height: normal;
      }
    }

    .video .box-video iframe {
      width: 100%;
      height: 60vh;
    }
  }
}

@media (max-width: 767px) {
  #expositor-video {
    .video {
      flex-direction: column;
      display: block;

      .desc .titulo {
        font-size: 1.3em;
        line-height: normal;
      }
      .desc div {
        font-size: 1em;
        padding-bottom: 1.5rem;
      }
    }
    .video:nth-child(even) .desc {
      margin-left: 0px;
      order: 1;
    }

    .main iframe {
      width: 100%;
      height: 60vh;
    }

    .main {
      h1 {
        @include body-large-serif;
        line-height: normal;
      }
    }

    .video .box-video iframe {
      width: 100%;
      height: 60vh;
    }

    .nao-possui-videos-container {
      h2 {
        font-size: 19.2px;
      }
    }
  }

  .container-sem-eventos {
    margin: 4% 4%;
  }
}
