.expositor-badge {
  background-color: $main-color-2 !important;
}

.plataforma-externa-badge {
    background-color: $main-color-6 !important;
  }
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  @include label-normal-sans-serif;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mr-1, .mx-1 {
    margin-right: 0 !important;
}
