.modal-perfil-usuario {
  .modal-perfil-usuario-body {
    padding: 0px !important;
  }

  .modal-perfil-usuario-tab-menu {
    border-bottom: 1px solid $bg-dark-5;
    button {
      width: 33.33%;
      border: 0px;
      border-right: 1px solid $bg-dark-5;
      background-color: transparent;
      padding: 30px;

      &:last-child {
        border-right: 0px;
      }

      #botao-alterar-dados {
        background-color: #f87fb4;
      }
    }
  }

  #dados-basicos {
    margin: 0 auto;

    .form-informacoes-pessoais {
      padding: 0px 40px;
    }

    .nome-completo {
      padding: 0px;
    }

    .col-dados-basicos {
      margin-top: 20px;

      justify-content: center;

      .input-modal-usuario {
        width: 100%;
        border: none;
        border-bottom: 2px solid #f87fb467;
        color: gray;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }

  #endereco-form-usuario-container {
    padding: 30px;

    .col-dados-endereco {
      margin-bottom: 30px;

      .input-modal-usuario-endereco {
        width: 100%;
        border: none;
        border-bottom: 2px solid #92d50142;
        color: gray;
        padding: 5px;

        &:focus-visible {
          outline: none;
        }
      }
    }

    .add-item {
      display: flex;
      justify-content: center;
    }
  }

  #contato-form-usuario-container {
    padding: 30px;
    z-index: 9999;

    .add-item {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      cursor: pointer;
    }
  }

  .modal-perfil-usuario-botoes {
    padding: 30px 15px;
  }

  .css-9gakcf-option {
    z-index: 9999;
  }

  .modal-perfil-usuario-botoes {
    .modal-perfil-botao-salvar {
      margin-right: 10px;
      padding: 9px 30px;
      background-color: $success-color-2;
      border: 0px;
      border-radius: 0px;
    }
    .modal-perfil-botao-salvar:hover {
      background-color: $success-color-3;
    }

    .modal-perfil-botao-cancelar {
      background-color: transparent;
      border: 1px solid $danger-color-3;
      padding: 9px 30px;
      color: $danger-color-3;
      border-radius: 0px;
    }
    .modal-perfil-botao-cancelar:hover {
      background-color: $danger-color-3;
      color: $bg-light;
    }
  }
}

.error {
  color: $danger-color-2;
}

.info-pass {
  padding: 20px 0;
  color: $danger-color-2;
  text-align: left;
  @include label-normal-sans-serif-1;

  .title-list {
    @include body-medium-sans-serif;
  }

  .check-success {
    color: green;
  }
}
