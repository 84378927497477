.cabecalho-branco-desk {
  display: flex;
  align-items: baseline;
  text-align: center;
  gap: 10px;
  align-self: stretch;
  margin-top: 56px;
  margin-bottom: 56px;
  p {
    color: var(--text-background-light-20, #fff);

    /* Body - sans serif - regular & bold/Body Small - Sans serif - Bold */
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  img {
    width: 14px;
    height: 10px;
  }
}

.cabecalho-preto-desk {
  display: flex;
  align-items: baseline;
  text-align: center;
  gap: 10px;
  align-self: stretch;
  margin-top: 36px;
  margin-bottom:0px;
  p {
    color: var(--text-background-light-20, #000000);

    /* Body - sans serif - regular & bold/Body Small - Sans serif - Bold */
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
  img {
    width: 10px;
    height: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .cabecalho-branco-mobile {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      color: var(--text-background-light-20, #fff);

      /* Body - sans serif - regular & bold/Body Small - Sans serif - Bold */
      font-family: "Raleway", sans-serif;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      margin: 0;
    }
    img {
      width: 14px;
      height: 10px;
    }
  }
 

  .cabecalho-preto-mobile {
    display: flex;
    align-items: baseline;
    text-align: center;
    gap: 10px;
    align-self: stretch;
    margin-top: 56px;
    margin-bottom: 56px;
    p {
      color: var(--text-background-light-20, #000000);

      /* Body - sans serif - regular & bold/Body Small - Sans serif - Bold */
      font-family: "Raleway", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    img {
      width: 10px;
      height: 10px;
    }
  }
}
