.page-full {
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: var(--text-background-dark-100, #000);
      text-align: center;

      /* Displays & headlines - serif/Display 1 - Serif - Light */
      font-family: "Roboto Slab", sans-serif;
      font-size: 96px;
      font-style: normal;
      font-weight: 300;
      line-height: 104px; /* 108.333% */
    }
  }
}

.container-page {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.filtros-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
}

.filtros-container input,
.filtros-container select {
  width: 250px;
  padding: 10px;
  border: 1px solid #000;
}

.pesquisa-por-letra-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.show-more-button {
  text-align: center;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0 !important;
  button {
    display: flex;
    padding: 10px 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid var(--text-background-dark-100, #000);
  }
}

@media only screen and (max-width: 600px) {
  .page-full {
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        color: var(--text-background-dark-100, #000);
        text-align: center;
        font-family: "Roboto Slab", sans-serif;
        font-size: 48px;
        font-weight: 300;
        line-height: 52px;
      }
    }
  }

  .container-page {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }

  .filtros-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
  }

  .filtros-container input,
  .filtros-container select {
    width: 100%;
    max-width: 300px;
  }

  .show-more-button {
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }

  .show-more-button button {
    padding: 10px 30px;
  }

  .page-full .title h1 {
    font-size: 36px;
    line-height: 40px;
  }

  .show-more-button button {
    padding: 10px 20px;
  }
}

.inicial-curso {
  margin: auto 5%;

  .box {
    padding-bottom: 40px;
    border-bottom: 2px solid #aaa;

    .inicial {
      font-size: 3rem;
      text-transform: uppercase;
      padding: 4rem 0 2rem;
    }
  }
}

.card-aula {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  margin-bottom: 20px;
  max-width: 1650px;
}

.box-alfabeto {
  margin-bottom: 30px;
  .title {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  .alfabeto {
    display: flex;
    justify-content: center;
    gap: 2rem;

    .letra {
      color: #ccc;
      &.destaque {
        color: #000;
      }
      &.pointer {
        cursor: pointer;
      }
    }
  }
}
