.background-duvidas-container {
  display: flex;
  background-image: url(../../img/expo-revestir/images/duvidas.svg);
  background-color: $main-color-7;


  .body-duvidas {
    flex: 1;
    padding: 5.5rem 0;

    .duvidas-header {
      width: 65%;
      margin: 0 auto;

      .h1-container {
        width: 20%;

        h1 {
          @include display-1-serif;
          margin-bottom: 1rem;
        }
      }

      span {
        color: $bg-dark-1;
        font-size: 1.7em;
        margin-top: 0.625em;
        padding-left: 12px;
      }
    }

    .duvidas-footer {
      width: 65%;
      margin: 0 auto;

      p {
        color: $bg-dark-1;
        @include body-large-sans-serif;
        margin-top: 3.5rem;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      button {
        cursor: pointer;
        @include body-large-serif;
        text-transform: uppercase;
        font-weight: bold;

        padding: 1.2rem 1.4rem;
        background-color: $bg-light;
      }
    }
  }

  .espaco-background {
    flex: 1;
  }
}

#acordeon {
  border-top: 2px solid #000;

  .card {
    box-shadow: none;
    border: none;

    .card-body {
      min-height: 1rem;
      color: $bg-dark-1;
    }
  }

  a {
    padding-top: 1.5rem;
    border-top: 1.5px solid $main-color-7;
    color: $bg-dark-1;
    display: flex;
    width: 65%;
    margin: 0 auto;
    font-size: 1.2em;

    i {
      margin-left: 2rem;
    }
  }

  #accordion .panel-title a:hover,
  #accordion .panel-title a:focus {
    color: rgb(212, 212, 212);
  }

  .card-header {
    border: 0px;
    background-color: $main-color-7 !important;
    border-bottom: 2px solid #000;
  }

  .card-body {
    background-color: #ebebf0;

    p {
      width: 65%;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
  .background-duvidas-container {
    background-image: none;
    background-color: $main-color-7;
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .background-duvidas-container {
    background-image: none;
    background-color: $main-color-7;
    display: block;
  }
}

@media (max-width: 767px) {
  .background-duvidas-container {
    background-image: none;
    background-color: $main-color-7;
    display: block;

    .body-duvidas {
      padding: 2rem 0;

      .duvidas-header {
        .h1-container {
          h1 {
            @include headline-3-sans-serif;
          }
        }

        span {
          padding-left: 0px;
        }
      }

      .duvidas-footer {
        p {
          @include body-normal-sans-serif;
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        }

        button {
          @include body-normal-sans-serif;
        }
      }
    }
  }

  #acordeon a {
    width: 70%;
    font-size: 0.75em;
  }
}
