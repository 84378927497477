.input-modal-usuario-phone {
  width: 100%;
  border: none;
  border-bottom: 2px solid #52cae85b;
  color: gray;
  padding: 5px;
  border-radius: 2px;
  background-clip: unset;

  &:focus-visible {
    outline: none;
  }
}
