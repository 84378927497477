.dialog-botao-confirmar {
  padding: 10px 30px;
  background-color: #0edeac;
  border: 0px;
  border-radius: 0px;

  &:hover {
    background-color: #44ebc4;
  }
}

.dialog-botao-cancelar {
  background-color: transparent;
  border: 1px solid #f24949;
  padding: 9px 30px;
  color: #f24949;
  border-radius: 0px;

  &:hover {
    background-color: #f24949;
    border-color: #f24949;
  }
}
