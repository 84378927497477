.container-card {
  display: flex;
  width: 540px;
  height: 306px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--text-background-light-60, #f2f2f5);
  cursor: pointer;

  .progress-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
    align-self: stretch;
    progress {
      width: 100%;
      height: 10px;
      appearance: none;
    }

    progress::-webkit-progress-bar {
      background-color: #ddd;
      border-radius: 5px;
    }

    progress::-webkit-progress-value {
      background-color: #4caf50;
      border-radius: 5px;
    }

    .progress-text {
      font-size: 14px;
      color: #4caf50;
    }

    .text {
      color: #767676;
    }
  }

  .container-logo-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex: 1 0 0;
    align-self: stretch;
    .logo {
      width: 90px;
      height: 90px;
      background: lightblue;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .container-nome-empresa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    flex: 1 0 0;

    h1 {
      align-self: stretch;
      color: #000;
      font-family: "Roboto Slab", sans-serif !important;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
    p {
      color: #767676;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .container-horas-aulas {
    display: flex;
    width: 484px;
    justify-content: flex-end;
    align-items: center;
    gap: 5.873px;
    p {
      color: var(--text-background-dark-100, #000);
      text-align: center;
      font-family: "Roboto Slab", sans-serif;
      font-size: 11.746px;
      font-style: normal;
      font-weight: 300;
      line-height: 16.966px; /* 144.444% */
    }

    .icon-horas {
      display: flex;
    }
    img {
      width: 16.314px;
      height: 16.314px;
      margin-right: 5px;
    }
  }
}

/* Media query para telas menores, como smartphones */
@media only screen and (max-width: 600px) {
  .container-card {
    width: 100%;
    height: 100%;
  }

  .container-card .container-horas-aulas {
    width: auto;
    justify-content: flex-start;
  }

  .container-nome-empresa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    flex: 1 0 0;

    h1 {
      align-self: stretch;
      color: #000;
      font-family: "Roboto Slab", sans-serif !important;
      font-size: 18px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
    p {
      color: #767676;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .container-logo-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
    flex: 1 0 0;
    align-self: stretch;
    padding: 0 !important;
    .logo {
      width: 60px !important;
      height: 60px !important;
      background: lightblue;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
