.btn {
  box-sizing: border-box;
  cursor: pointer;
  text-transform: none;
  border-radius: none;
  &.btn-border,
  &.btn-link {
    background-color: none;
  }
}

.btn-login {
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.75rem 7rem;
  @include body-medium-sans-serif;
  font-weight: normal;
  background-color: $bg-dark-1;
  color: $bg-light;
  border: none;
  max-height: 50px;

  &:focus,
  &:active,
  &:hover {
    background-color: $bg-dark-1 !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0.25);
  }
}

.btn-cta-home-light {
  box-sizing: border-box;
  border-radius: 0;
  padding: 1rem 3rem;
  @include body-xlarge-sans-serif;
  font-weight: normal;
  background-color: transparent;
  color: $bg-light;
  border: 3px solid $bg-light;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-light !important;
    background-color: $bg-light !important;
    color: $bg-dark-1 !important;
  }
}

.btn-cta-home-dark {
  box-sizing: border-box;
  border-radius: 0;
  padding: 1rem 3rem;
  @include body-xlarge-sans-serif;
  font-weight: normal;
  background-color: transparent;
  color: $bg-dark-1;
  border: 3px solid $bg-dark-1;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-dark-1 !important;
    background-color: $bg-dark-1 !important;
    color: $bg-light !important;
  }
}

.btn-event-dark {
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.625rem 3rem;
  @include body-normal-sans-serif;
  font-weight: normal;
  background-color: transparent;
  color: $bg-dark-1;
  border: 3px solid $bg-dark-1;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-dark-1 !important;
    background-color: $bg-dark-1 !important;
    color: $bg-light !important;
  }

  &:disabled {
    border: 3px solid $bg-dark-5 !important;
    background-color: $bg-dark-5 !important;
    color: $bg-dark-4 !important;
    cursor: not-allowed;
  }
}

.btn-event-light {
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.625rem 3rem;
  @include body-normal-sans-serif;
  font-weight: normal;
  background-color: transparent;
  color: $bg-light;
  border: 3px solid $bg-light;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-light !important;
    background-color: $bg-light !important;
    color: $bg-dark-1 !important;
  }

  &:disabled {
    border: 3px solid $bg-dark-5 !important;
    background-color: $bg-dark-5 !important;
    color: $bg-dark-4 !important;
    cursor: not-allowed;
  }
}

.btn-galeria-light {
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.625rem 2.5rem;
  @include body-normal-sans-serif;
  font-weight: normal;
  background-color: transparent;
  color: $bg-light;
  border: 3px solid $bg-light;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-light !important;
    background-color: $bg-light !important;
    color: $bg-dark-1 !important;
  }
}

.btn-galeria-dark {
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.625rem 2.5rem;
  @include body-normal-sans-serif;
  font-weight: normal;
  background-color: $bg-dark-1;
  color: $bg-color;
  border: 3px solid $bg-dark-1;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-dark-1 !important;
    background-color: $bg-dark-1 !important;
    color: $bg-light !important;
  }
}

.btn-galeria-light {
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.625rem 2.5rem;
  @include body-normal-sans-serif;
  font-weight: normal;
  background-color: transparent;
  color: $bg-light;
  border: 3px solid $bg-light;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-light !important;
    background-color: $bg-light !important;
    color: $bg-dark-1 !important;
  }
}

.btn-card-event {
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.625rem 2.5rem;
  @include body-normal-sans-serif;
  font-weight: normal;
  background-color: $bg-color !important;
  color: $bg-dark-1 !important;
  border: 3px solid $bg-color;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-light !important;
    background-color: $bg-light !important;
    color: $bg-dark-1 !important;
  }
}

.btn-show-more {
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.625rem 2.5rem;
  @include body-normal-sans-serif;
  font-weight: normal;
  background-color: transparent;
  color: $bg-dark-1;
  border: 3px solid $bg-dark-1;

  &:focus,
  &:active,
  &:hover {
    border: 3px solid $bg-dark-1 !important;
    background-color: $bg-dark-1 !important;
    color: $bg-light !important;
  }
}
