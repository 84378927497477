//Tipografia

//Displays e headlines - serif
@mixin display-1-serif {
  font-family: $font-system;
  font-size: 6em;
  line-height: 104px;
}

@mixin display-2-serif {
  font-family: $font-system;
  font-size: 5em;
  line-height: 88px;
}

@mixin headline-1-serif {
  font-family: $font-system;
  font-size: 4.5em;
  line-height: 80px;
}

@mixin headline-2-serif {
  font-family: $font-system;
  font-size: 4em;
  line-height: 72px;
}

@mixin headline-3-serif {
  font-family: $font-system;
  font-size: 3.125em;
  line-height: 58px;
}

@mixin headline-4-serif {
  font-family: $font-system;
  font-size: 2.5em;
  line-height: 48px;
  font-weight: normal;
}

//Displays e headlines - sans-serif
@mixin display-1-sans-serif {
  font-family: $font-system;
  font-size: 6em;
  line-height: 104px;
}

@mixin display-2-sans-serif {
  font-family: $font-system;
  font-size: 5em;
  line-height: 88px;
}

@mixin headline-1-sans-serif {
  font-family: $font-system;
  font-size: 4.5em;
  line-height: 80px;
}

@mixin headline-2-sans-serif {
  font-family: $font-system;
  font-size: 4em;
  line-height: 72px;
}

@mixin headline-3-sans-serif {
  font-family: $font-system;
  font-size: 3.125em;
  line-height: 58px;
}

@mixin headline-4-sans-serif {
  font-family: $font-system;
  font-size: 2.5em;
  line-height: 48px;
}

//Body - serif
@mixin body-xlarge-serif {
  font-family: $font-system;
  font-size: 2.25em;
  line-height: 44px;
}

@mixin body-large-serif {
  font-family: $font-system;
  font-size: 1.5em;
  line-height: 32px;
}

@mixin body-medium-serif {
  font-family: $font-system;
  font-size: 1.125em;
  line-height: 26px;
}

@mixin body-normal-serif {
  font-family: $font-system;
  font-size: 1em;
  line-height: 24px;
}

//Body - sans-serif
@mixin body-xlarge-sans-serif {
  font-family: $font-system;
  font-size: 2.25em;
  line-height: 44px;
}

@mixin body-large-sans-serif {
  font-family: $font-system;
  font-size: 1.5em;
  line-height: 32px;
}

@mixin body-medium-sans-serif {
  font-family: $font-system;
  font-size: 1.125em;
  line-height: 26px;
}

@mixin body-normal-sans-serif {
  font-family: $font-system;
  font-size: 1em;
  line-height: 24px;
}

//Label sans-serif
@mixin label-normal-sans-serif-1 {
  font-family: $font-system;
  font-size: 0.875em;
  line-height: 22px;
}

@mixin label-normal-sans-serif {
  font-family: $font-system;
  font-size: 0.75em;
  line-height: 20px;
}

@mixin label-normal-sans-serif-3 {
  font-family: $font-system;
  font-size: 0.625em;
  line-height: 18px;
}


