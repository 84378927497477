.modal-fale-com-o-expositor {
  .modal-content {
    .modal-header {
      background-color: $bg-color !important;
    }
    background-color: $bg-color;
    .container-texto {
      textarea {
        width: 100%;
        height: 30vh;
        padding: 12px;
        outline: none;
        border-radius: 5px;
      }
    }

    .modal-fale-com-expositor-footer {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
