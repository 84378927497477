.footer {
  .links {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-weight: normal;

      > li {
        display: inline-block;
        padding-right: 20px;
      }
      > li:last-child {
        padding-right: 0px;
      }
      > li:first-child {
        a {
          @include body-medium-serif;
          margin-bottom: 1rem;
          color: $bg-light;
          text-transform: uppercase;
          font-weight: bold;

          &:hover,
          &:focus {
            color: $bg-light;
          }
        }
      }

      a:not(.btn) {
        color: $bg-light;
        display: block;
        font-size: 0.875em;
        line-height: 1.375rem;
        margin-bottom: 15px;
        &:hover,
        &:focus {
          color: $bg-light;
        }
      }

      &.uppercase-links {
        text-transform: none;
      }
      &.stacked-links {
        margin-top: 10px;

        > li {
          display: block;
          line-height: 26px;
        }
        h4 {
          margin-top: 0px;
        }
      }
    }
  }
}

.footer .links ul.stacked-links {
  margin-top: 0;
}

.bg-footer {
  background-color: $bg-dark-1;
  padding: 80px 0 130px 0;
}

.footer {
  position: static;
}

.row-footer {
  img {
    width: 100%;
  }
}

ul {
  display: flex;
  flex-direction: column;
  padding: 0%;
}

li {
  list-style: none;
  @include body-medium-sans-serif;
}

a {
  color: $bg-dark-1;

  &:hover,
  &:focus {
    color: $bg-dark-1;
  }
}

.social-media-footer {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.social-media-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  li {
    margin: 0 0.6rem;

    a {
      color: $bg-dark-1;

      i {
        &::before {
          font-size: 24px;
        }
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.footer-assentador {
  background-color: $bg-dark-1;

  .logos {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    padding: 15px 0;
    p {
      color: #fff;
      margin-bottom: unset;
    }

    .realizacao {
      img {
        height: 50px;
      }
    }
  }
}
