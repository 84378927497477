.p-message-summary {
  display: none;
}

.p-message-close {
  .p-link {
    display: none;
  }
}

.p-message-close {
  display: none;
}
