.bg-visitante {
  width: 100%;
  justify-content: space-between;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;

  .row-right-visitante {
    width: 100%;
  }

  .login-text-form-visitante {
    width: 520px;
  }

  .container-info-visitante {
    p {
      text-align: left;
      color: #000;

      .mes-edicao {
        text-transform: uppercase;
      }
    }
  }

  .selecionar-idioma {
    display: flex;
    justify-content: center;
  }

  p {
    text-align: center;
    font-size: 56px;
    line-height: 0.9;
  }

  .box-logo {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .logo {
      text-align: center;
      p {
        font-size: 1.5rem;
        margin: unset;
        padding-top: 5px;
        text-align: center;
      }
    }

    p {
      font-size: 2rem;
      margin-left: 20px;
      text-align: left;
    }
  }
}

.login_wrapper-visitante {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  .row {
    justify-content: center;
    align-items: center;
  }

  .row-right {
    img {
      position: absolute;
      width: 66%;
    }
  }

  .error-menssage {
    margin-top: 10px;
    // @include label-normal-sans-serif;
    // color: $main-color-6;
  }
}

@media (max-width: 800px) {
  .row-login-visitante {
    flex-direction: column-reverse;
  }
}
