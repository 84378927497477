.filtragem-e-info-container {
  h1 {
    
    font-weight: $font-weight-bold;
  }
  text-align:start;
  padding-top: 70px;
}

.eventos-passados-container {
  background-color:$bg-light;
  padding-bottom: 80px;
}

.card-evento {
  img {
    width: 100%;
  }
}
