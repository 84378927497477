.header-container-expositor-nav {
  text-align: center;

  h1 {
    padding: 20px 0;
  }
}

.expositor-loading-container {
  padding: 10% 0;
}

.expositor-headline-container {
  background-color: $bg-color;
}
