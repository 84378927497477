.nav-bar-container {
  margin: 30px 0;
  .logo-expositor-container {
    padding-left: 0px;
    .logo-expositor {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      img {
        max-width: 80%;
        padding: 5px;
      }
    }
  }

  .social-media-nav-expositor-container {
    justify-content: flex-end;

    div {
      justify-content: flex-end;
    }
    a {
      color: $bg-dark-4;
      font-size: 24px;
    }
  }
}

.nav-bar-menu {
  margin-top: -60px;
  .nav-bar-central-container {
    flex: 2.5;

    .nav-icones {
      display: flex;
      width: 100%;
      .central-container-icons {
        margin-top: 1.062rem;
        width: 100%;
        display: flex;
        margin-left: 10px;
        ul {
          flex-direction: row;
          width: 20%;
          display: flex;
          margin-top: 1rem;
          gap: 50px;
          li {
            font-size: 25px;
          }
        }

        img {
          cursor: pointer;
        }
      }
    }

    .nav-items {
      width: 100%;
      padding-left: 10px;

      ul {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        li {
          a,
          div {
            @include body-large-sans-serif;
            color: #000000;
            cursor: pointer;
            padding-right: 2.5rem;
          }
          div:hover {
            text-decoration: underline;
          }
          &.active {
            a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .navbar {
    background-color: transparent;

    .navbar-nav {
      flex-direction: row;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .nav-bar-menu {
    margin-top: -20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .nav-bar-menu {
    margin-top: -20px;
    .navbar {
      background-color: white;

      .navbar-nav {
        flex-direction: row;

        li {
          @include body-normal-sans-serif;
        }
      }
    }
  }

  .nav-bar-menu .nav-bar-central-container .nav-items ul li a,
  .nav-bar-menu .nav-bar-central-container .nav-items ul li div {
    padding-right: 1rem;
  }

  .nav-bar-container {
    .social-media-nav-expositor-container {
      margin-top: 30px;
      justify-content: flex-start;

      a {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 767px) {
  .nav-bar-container {
    .social-media-nav-expositor-container {
      margin-top: 30px;
      justify-content: flex-start;

      a {
        font-size: 24px;
      }
    }
  }

  .nav-bar-menu {
    margin-top: -20px;
    .navbar {
      .navbar-nav {
        flex-direction: column;

        li {
          @include body-normal-sans-serif;
        }
      }
    }
  }

  .nav-bar-menu .nav-bar-central-container .nav-items ul {
    align-items: flex-start;
  }
  .nav-bar-menu .nav-bar-central-container .nav-items {
    padding-left: 0px;
  }

  .navbar .navbar-nav {
    max-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    margin: 0;
  }
}

.aviso-link-copiado {
  padding: 0 0 0 1.3rem;
  @include body-normal-sans-serif;
  color: gray;
  position: absolute;
}

.aviso-link-copiado-produto {
  @include body-normal-sans-serif;
  color: gray;
  position: absolute;
  margin-top: 58px;
  margin-left: -60px;
}

.favoritar-pointer {
  cursor: pointer;
}

.compartilhar-icone {
  filter: brightness(0);
}