/* style.css */

.container-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1% auto !important;
}

.header-assentador {
  background-color: #000;
  color: #e1e1e1;
  font-size: 1.8rem;
  padding: 0 6%;
  height: 130px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .logo-assentador-anfacer {
    max-width: 468px;
    img {
      width: 100%;
    }
  }
}

.container-meu-aprendizado {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto 5%;
  margin-top: 108px;
  .title-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .button-list button {
    display: inline-flex;
    padding: 20px 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 3px solid var(--text-background-dark-100, #000);
    color: var(--text-background-dark-100, #000);
    text-align: center;

    /* Body - sans serif - regular & bold/Body Large - Sans serif - Regular */
    font-family: "Raleway", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 122.222% */
  }
}

.container-cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto 5%;
  margin-top: 108px;

  .container-cards-aulas {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;
  }
  h3 {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 60px;
  }
  .button-ver-mais {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .button-ver-mais button {
    color: var(--text-background-dark-40, #8c8c8c);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto Slab", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px; /* 126.667% */
  }
}

.container-novos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto 5%;
  margin-top: 108px;

  .container-cards-aulas-novos {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;
  }
  h3 {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 60px;
  }
  .button-ver-mais {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .button-ver-mais button {
    color: var(--text-background-dark-40, #8c8c8c);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto Slab", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px; /* 126.667% */
  }
}

@media only screen and (max-width: 996px) {
  .header-assentador {
    .logo-assentador-anfacer {
      max-width: 300px;
    }
  }
}

/* Estilos para telas menores, como smartphones */
@media only screen and (max-width: 600px) {
  .header-assentador {
    font-size: 1.2rem;
    justify-content: center;
  }

  .container-meu-aprendizado {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .container-cards,
  .container-novos {
    margin-top: 80px;
  }

  .button-list button {
    width: 100%;

    display: inline-flex;
    padding: 20px 40px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 3px solid var(--text-background-dark-100, #000);
    color: var(--text-background-dark-100, #000);
    text-align: center;

    /* Body - sans serif - regular & bold/Body Large - Sans serif - Regular */
    font-family: "Raleway", sans-serif;
    font-size: 26px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; /* 122.222% */
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .container-meu-aprendizado {
    margin-top: 90px;
  }

  .container-cards,
  .container-novos {
    margin-top: 90px;
  }
}
