.expositor-contato {
  background-color: $bg-light;
  padding: 4.375rem 0rem;
}

.sobre-expositor-container {
  border-right: 1px solid #8c8c8c;

  .sobre-expositor {
    width: 85%;

    h1 {
      @include headline-3-serif;
      padding-bottom: 1.25rem;
      font-weight: $font-weight-bold;
    }
    p {
      color: $bg-dark-1;
      @include body-large-sans-serif;
    }
  }
}

.contato-expositor {
  padding-left: 10%;
  h1 {
    @include headline-3-serif;
    font-weight: $font-weight-bold;
    padding-bottom: 10px;
  }
  li {
    color: $bg-dark-1;
    @include body-large-serif;
    margin-bottom: 10px;
  }
}

.ul-rede-social {
  li {
    display: inline-block;
    width: 30%;
  }
}

.video-container {
  background-color: $bg-color;
  padding: 5rem;
  .container-info {
    padding-top: 7rem;

    h1 {
      @include headline-3-serif;
      font-weight: $font-weight-bold;
    }

    p {
      @include body-large-sans-serif;
    }
  }
}

.numeros-nao-mentem {
  width: 100%;
  padding: 3.984rem 0 3.984rem 0;
  background-color: $bg-light;
  .numeros-nao-mentem-h1 {
    text-align: start;

    h1 {
      padding-bottom: 4.812rem;
      @include headline-3-serif;
      font-weight: $font-weight-bold;
    }
  }

  .box {
    color: $bg-dark-1;

    h1 {
      text-align: center;
      padding: 30px 50px 6px 50px;
      @include headline-1-sans-serif;
      font-weight: $font-weight-bold;
    }

    p {
      @include body-medium-sans-serif;
      text-align: center;
      padding: 0px 2px;
    }
  }
}

.box-background {
  background-color: $bg-box;
  padding: 20px 0;
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
  // informação geral
  .sobre-expositor-container {
    .sobre-expositor {
      h1 {
        @include headline-4-serif;
        font-weight: $font-weight-bold;
      }
      p {
        @include body-medium-sans-serif;
      }
    }
  }

  .contato-expositor {
    padding-left: 3rem;
    h1 {
      padding-bottom: 1rem;
      @include headline-4-serif;
      font-weight: $font-weight-bold;
    }
    li {
      @include body-medium-sans-serif;
    }
  }

  // video container
  .video-container {
    .container-info {
      padding-top: 0px;

      h1 {
        @include headline-3-serif;
        font-weight: font-weight-semi;
      }
  
      p {
        @include body-large-sans-serif;
        color: rgba(140, 140, 140, 1);
      }
    }
  }
  .box-background {
    margin-bottom: 30px;
  }
  // números não mente
  .numeros-nao-mentem {
    .numeros-nao-mentem-h1 {
      h1 {
        @include headline-4-serif;
        font-weight: $font-weight-bold;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  // informação geral
  .sobre-expositor-container {
    .sobre-expositor {
      h1 {
        @include body-xlarge-serif;
      }
      p {
        @include body-medium-sans-serif;
      }
    }
  }

  .contato-expositor {
    padding-left: 3rem;
    h1 {
      padding-bottom: 1rem;
      @include body-xlarge-serif;
    }

    ul {
      li {
        @include body-medium-sans-serif;
        a {
          text-align: center;
          i {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  ul.social-media-ul {
    flex-direction: column !important;
  }

  // video container
  .video-container {
    .container-info {
      padding-top: 1rem;

      h1 {
        @include headline-3-serif;
        font-weight: font-weight-semi;
      }
  
      p {
        @include body-large-sans-serif;
        color: rgba(140, 140, 140, 1);
      }
    }
  }
  .box-background {
    margin-bottom: 30px;
  }
  // números não mente
  .numeros-nao-mentem {
    .numeros-nao-mentem-h1 {
      h1 {
        @include headline-4-serif;
        font-weight: $font-weight-bold;
      }
    }
  }
}

@media (max-width: 767px) {
  // informação geral
  .sobre-expositor-container {
    border-right: none;
    border-bottom: 1px solid #8c8c8c;

    .sobre-expositor {
      width: 100%;
      h1 {
        @include body-xlarge-serif;
      }
      p {
        @include body-medium-sans-serif;
      }
    }
  }

  .contato-expositor {
    padding-top: 2rem;
    h1 {
      padding-bottom: 0.5rem;
      @include body-xlarge-serif;
    }
    li {
      @include body-medium-sans-serif;
    }
  }

  ul.social-media-ul {
    justify-content: flex-start !important;
  }

  // video container
  .video-container {
    .container-info {
      padding-top: 1rem;

      h1 {
        @include headline-3-serif;
        font-weight: font-weight-semi;
      }
  
      p {
        @include body-large-sans-serif;
        color: rgba(140, 140, 140, 1);
      }
    }
  }
  .box-background {
    margin-bottom: 30px;
  }
  // números não mente
  .numeros-nao-mentem {
    .numeros-nao-mentem-h1 {
      h1 {
        @include headline-4-serif;
        font-weight: $font-weight-bold;
      }
    }
  }
}
