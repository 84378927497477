.promocao {
  margin-bottom: 80px;
  margin-top: 80px;
  .promocao-itens {
    padding: 40px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    .hgturismo {
      margin-top: 40px;
    }
    h1 {
      @include body-large-sans-serif;
      margin-bottom: 10px;
      color: $bg-dark-3;
    }
  }

  .promocao-apoio {
    border-top: 1px solid $main-color-5;
    padding: 40px 0;
    text-align: center;

    h1 {
      @include body-xlarge-serif;
      margin-bottom: 10px;
    }
  }

  .row {
    .col-sm-3 {
      text-align: center;
    }
  }
}
