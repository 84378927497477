.modal-ver-produto {
  z-index: 99;
  max-width: 930px !important;
  padding-top: none;
  padding: none !important;

  .modal-content {
    border-radius: 0px;
    border: 0px;
    background-color: transparent;
    box-shadow: none;
  }

  .modal-body {
    padding: 0 !important;
  }

}

@media (min-width: 1200px) and (max-width: 1799px) {
  .modal-ver-produto {
    max-width: 600px !important;
  }
}
