.carrosel-evento-destaque {
  background-color: $main-color-3;
  margin-top: -1px;
  padding: 6.5rem 0;

  .fyLrfx {
    margin-top: 30px !important;
    z-index: 50;
  }

  .data-evento-carrosel {
    background-color: $bg-dark-1;
    position: absolute;
    width: 3.75rem;
    height: 3.75rem;
    padding-top: 0.5rem;
    margin-top: 3px;

    span {
      @include body-medium-serif;
      text-align: center;
      line-height: 120%;
      color: $bg-light;
      display: block;
    }
  }

  .evento-categoria-1,
  .evento-categoria-2,
  .evento-categoria-3 {
    width: 100%;
    height: 259px;
    background-size: cover;
  }
  .evento-categoria-1 {
    border-top: 3px solid #52c9e8;
  }
  .evento-categoria-2 {
    border-top: 3px solid #60269e;
  }
  .evento-categoria-3 {
    border-top: 3px solid #f87fb4;
  }

  .card-body {
    color: $bg-dark-1;
    background-color: $bg-light;

    .tags-evento {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-items: center;
      margin-bottom: 15px;

      p {
        margin: 0%;
        color: #fff;
      }

      .tag-evento-categoria-1 {
        background-color: #52c9e8;
        padding: 1px 5px;
        margin-right: 8px;
      }
      .tag-evento-categoria-2 {
        background-color: #60269e;
        padding: 1px 5px;
        margin-right: 8px;
      }
      .tag-evento-categoria-3 {
        background-color: #f87fb4;
        padding: 1px 5px;
        margin-right: 8px;
      }
    }

    min-height: 11rem;

    .plataforma-evento {
      p {
        @include label-normal-sans-serif;
        font-size: 15px;
        line-height: 1.5em;
      }

      .plataforma-evento-expo {
        background-color: #93d501;
        padding: 1px 5px;

        p {
          margin: 0px;
          color: #fff;
        }
      }

      .plataforma-evento-externa {
        background-color: #fe391f;
        padding: 1px 5px;

        p {
          margin: 0px;
          color: #fff;
        }
      }
    }

    .text-container {
      h3 {
        @include body-large-serif;
      }

      p {
        @include label-normal-sans-serif-1;
        min-height: 88px;
      }
    }

    .card-footer-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .evento-data {
        display: flex;
        flex-direction: column;

        .evento-horas-data {
          p {
            @include body-normal-serif;
            color: $bg-dark-1;
            display: flex;
            &::before {
              padding-right: 25px;
              padding-bottom: 5px;
              content: "";
              background: url("../../../assets/img/expo-revestir/icons/relogio.svg");
              width: 20px;
              height: 25px;
              background-repeat: no-repeat;
              filter: invert(100%);
            }
          }
        }
        .evento-calendario-data {
          display: flex;
          align-items: center;
         
            &::before {
              padding-right: 25px;
              padding-bottom: 5px;
              content: "";
              background-image: url("../../../assets/img/icons/calendario.svg");
              width: 20px;
              height: 25px;
              background-repeat: no-repeat;
              
           
          }
        }
      }}

      &:hover {
        background-color: $bg-color;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      .botao-card-evento{
        border: 3px solid $bg-light !important;
        background-color: $bg-light !important;
        color: $bg-dark-1 !important;
      }
  }
}

// RESPONSIVIDADE
@media screen and (min-width: 1800px) {
}

@media (min-width: 1200px) and (max-width: 1799px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 767px) {
  .dYWSjd {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0px !important;
  }

  .card-body {
    .card-footer-container {
      .evento-data-container {
        display: flex;
        justify-content: center;
      }
      .button-card-eventos {
        display: flex;
        justify-content: center;
      }
    }
  }
}
}