.endereco-form-container {
  .endereco-individual-container {
    margin-bottom: 50px !important;
  }
}

.input-modal-usuario-empresa {
  width: 100%;
  border: none;
  border-bottom: 2px solid #52cae85b;
  color: gray;
  padding: 5px;

  &:focus-visible {
    outline: none;
  }
}
