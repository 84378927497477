.evento-titulo {
  h1 {
    @include headline-2-serif;
  }
}

.evento-descricao-container {
  padding-bottom: 4rem;
}

.evento-data {
  display: flex;
  h3 {
    @include headline-4-serif;
    font-size: 24px;
  }

  .data-inicio {
    padding: 0px 10px 10px 0px;
  }

  p {
    margin: 0;
  }

  #horario-evento {
    h3 {
      &::before {
        padding-right: 30px;
        content: "";
        background-image: url("../../../assets/img/expo-revestir/icons/relogio.svg");
        background-repeat: no-repeat;
        font-size: 0.7em;
        line-height: 0;
        filter: invert(1);
      }
    }
  }

  .horario-evento {
    padding: 0px 10px 10px 20px;
  }
}

.main-container-evento {
  margin: 0 auto;

  .evento-container {
    width: 100%;
    display: flex;
    margin-top: 22px;
    .evento-chat-container {
      width: 100%;
      text-align: center;
    }
  }

  .evento-video-container {
    h1 {
      padding: 140px;
    }
  }
}

.row-container-logos {
  text-align: center;

  .col-container-logos {
    margin-bottom: 20px;

    .titulo-logo-container {
      color: white;
      word-break: break-all;
    }

    .logotipo-container {
      background-color: rgb(255, 255, 255);
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));

      img {
        width: 100%;
        padding: 10px;
      }
    }
  }
}

.container-video-centralizado {
  text-align: center;
}

.evento-texto-descritivo-container {
  padding-top: 6rem;

  p {
    width: 60%;
    @include body-normal-sans-serif;
  }
}

.voltar-eventos {
  padding-top: 100px;
  cursor: pointer;

  p {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background-image: url(../../img/expo-revestir/icons/Arrow1.svg);
      background-repeat: no-repeat;
      background-position: center;
      padding: 0rem 1rem;
    }
  }
}

.evento-logos-bg {
  margin-top: 5rem;
  background-color: $main-color-2;
  padding: 60px 0;

  h1 {
    text-align: center;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1800px) {
  .evento-video-container {
    width: 100%;
    h1 {
      padding: 140px;
    }
  }

  .evento-container {
    .iframe-video {
      width: 100%;
    }

    .evento-chat-container {
      width: 38% !important;
      text-align: center;

      .iframe-chat {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .evento-video-container {
    width: 80%;
    h1 {
      padding: 140px;
    }
  }

  .evento-container {
    .iframe-video {
      width: 100%;
    }

    .evento-chat-container {
      width: 38% !important;
      text-align: center;

      .iframe-chat {
        width: 100%;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .evento-titulo {
    h1 {
      @include headline-3-serif;
    }
  }

  .evento-container {
    flex-direction: column;

    .iframe-video {
      width: 100%;
    }
    .evento-chat-container {
      width: 100%;
      text-align: center;

      .iframe-chat {
        width: 100%;
        height: 350px;
      }
    }
  }

  .evento-texto-descritivo-container {
    padding-top: 3rem;

    p {
      width: 80%;
      @include body-normal-sans-serif;
    }
  }
}

@media (max-width: 991px) {
  .voltar-eventos {
    padding-top: 7%;
  }

  .evento-titulo {
    h1 {
      @include headline-4-serif;
    }
  }

  .evento-descricao-container {
    padding-bottom: 1rem;
  }

  .banner-container {
    img {
      width: 100%;
      height: 260px;
    }
  }
  .container {
    max-width: 900px;
  }

  .evento-container {
    flex-direction: column;

    .iframe-video {
      width: 100%;
    }
    .evento-chat-container {
      width: 100%;
      text-align: center;

      .iframe-chat {
        width: 100%;
        height: 350px;
      }
    }
  }

  .evento-texto-descritivo-container {
    padding-top: 3rem;

    p {
      width: 100%;
      @include body-normal-sans-serif;
    }
  }
}
