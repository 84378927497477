.modal-header {
  h5 {
    @include headline-1-serif;
    color: $bg-dark-1;
  }

  background-color: $main-color-4 !important;
  border: none;
  box-shadow: none;
  padding: 1rem 1rem;
}

.modal-footer {
  border: none;
  padding: 0.75rem;
  border-radius: 0%;
  justify-content: space-between;
}

.modal-evento {


  .imagem-evento {
    width: 100%;
    height: 100%;
    padding-top: 15px;
  }

  .modal-content {
    box-shadow: none;
    background-color: $main-color-2;
    border: none;
    border-radius: 0%;

    .modal-body {
      position: relative;
      flex: 1 1 auto;
      border: none;
      padding: 0px !important;

      .evento-info-container {
        .evento-categoria {
          margin: 0 0 15px 5px;
          color: #000;
          font-size: 1em;
        }

        .evento-palestrante-title {
          margin: 0 0 10px 5px;
          font-size: 1.125em;
          color: #000;
        }

        .evento-palestrante {
          margin-left: 5px;
          font-size: 1em;
          color: #000;
          font-weight: bold;
        }
      }

      .container {
        .row {
          width: 100%;
          margin: 0;
          .col-sm-6 {
            padding-left: 10px;

            img {
              width: 100%;
            }

            h1 {
              @include body-xlarge-serif;
              font-size: 1.8em;
            }
          }
        }
      }

      .evento-data {
        p {
          margin-bottom: 10px;
          @include body-medium-serif;
          &::before {
            padding-right: 30px;
            content: "";
            background-image: url("../../../assets/img/expo-revestir/icons/relogio.svg");
            background-repeat: no-repeat;
            font-size: 0.9em;
            line-height: 0;
            filter: invert(1);
          }
        }
      }

      textarea {
        width: 100%;
        height: 400px;
        border: none;
        padding: 30px;
        font-size: 14px;
        outline: none;
      }

      .titulo-evento {
        @include body-xlarge-serif;
      }

      .descricao-evento {
        @include body-medium-sans-serif;
      }
    }

    .modal-footer {
      padding: 20px !important;

      .col-botoes-modal-footer {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .modal-dialog {
    margin: 1.75rem auto;
  }

  // RESPONSIVIDADE
  @media (max-width: 580px) {
    .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto;
      padding: 2.5rem;
    }

    .modal-content .modal-body .container .row {
      width: 100%;
      margin: 0;
    }

    .modal-content .modal-body .container .row .col-sm-6 {
      text-align: center;

      img {
        width: 50%;
      }
    }

    .evento-data {
      display: flex;
    }

    .modal-content .modal-body {
      .titulo-evento {
        font-size: 1.7em;
      }

      .descricao-evento {
        font-size: 1.2em;
        line-height: 26px;
      }
    }
  }
}
